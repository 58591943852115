import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner.js';
import Button from '../common/Button.js';

const Subscribe = ({ onClose }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('credit-card');
  const [promoCode, setPromoCode] = useState('');
  const [promoMessage, setPromoMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}get-potential-subscriber-subscription-details/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setSubscriptionDetails(response.data);
        } catch (error) {
          navigate('/login');
        }
      }
    };

    fetchSubscriptionDetails();
  }, [navigate]);

  const handlePlanSelect = (plan, subscriptionId) => {
    setSelectedPlan(plan);
    setSelectedSubscriptionId(subscriptionId);
    setMessage('');
    setIsError(false);
  };

  const handlePromoCodeSubmit = async () => {
    const token = localStorage.getItem('accessToken');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}validate-promo-code/`,
        { promo_code: promoCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setPromoMessage(response.data.message);
      setIsError(false);

      if (
        response.status === 200 &&
        response.data.message.includes('Promo code applied successfully.')
      ) {
        window.location.reload();
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || 'Failed to apply promo code.';
      setPromoMessage(errorMessage);
      setIsError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      setMessage('Stripe has not been loaded.');
      setIsError(true);
      return;
    }

    if (!selectedSubscriptionId) {
      setMessage('Please select a subscription plan.');
      setIsError(true);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setMessage('Please enter your card details.');
      setIsError(true);
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setMessage(error.message);
        setIsError(true);
        setIsLoading(false);
        return;
      }

      const formData = {
        subscription_id: selectedSubscriptionId,
        payment_method_id: paymentMethod.id,
      };

      const token = localStorage.getItem('accessToken');

      const response = await axios.post(
        `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}purchase-subscription/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setMessage(response.data.message);
      setIsError(false);
      window.location.reload();
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || 'Failed to purchase subscription.';
      setMessage(errorMessage);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (!subscriptionDetails) {
    return <LoadingSpinner text="Loading subscription details..." />;
  }

  const monthlyCost = subscriptionDetails.monthly_cost?.toFixed(2);
  const yearlyCost = subscriptionDetails.yearly_cost?.toFixed(2);
  const oneDayPassValue = subscriptionDetails.one_day_pass_value?.toFixed(2);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-lg md:max-w-2xl lg:max-w-3xl overflow-y-auto max-h-[90vh]">
        {/* Exit Button Positioned Correctly Inside the Modal */}
        <div className="flex justify-end">
          <Button
            onClick={onClose}
            variant="outlinedPrimary"
            size="small"
            className="mb-4"
          >
            Exit
          </Button>
        </div>

        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Choose Your Subscription
        </h2>

        <div className="flex flex-col space-y-4">
          {oneDayPassValue && (
            <div
              className={`p-5 border rounded-lg text-center cursor-pointer transition ${
                selectedPlan === 'day'
                  ? 'border-primary-500 bg-primary-50'
                  : 'border-gray-300'
              }`}
              onClick={() =>
                handlePlanSelect(
                  'day',
                  subscriptionDetails.one_day_pass_value_id,
                )
              }
            >
              <p className="text-2xl font-semibold">${oneDayPassValue}</p>
              <p className="text-gray-600">1-Day Pass</p>
            </div>
          )}
          {monthlyCost && (
            <div
              className={`p-5 border rounded-lg text-center cursor-pointer transition ${
                selectedPlan === 'monthly'
                  ? 'border-primary-500 bg-primary-50'
                  : 'border-gray-300'
              }`}
              onClick={() =>
                handlePlanSelect(
                  'monthly',
                  subscriptionDetails.monthly_subscription_id,
                )
              }
            >
              <p className="text-2xl font-semibold">${monthlyCost}</p>
              <p className="text-gray-600">Monthly Subscription</p>
            </div>
          )}
          {yearlyCost && (
            <div
              className={`p-5 border rounded-lg text-center cursor-pointer transition ${
                selectedPlan === 'yearly'
                  ? 'border-primary-500 bg-primary-50'
                  : 'border-gray-300'
              }`}
              onClick={() =>
                handlePlanSelect(
                  'yearly',
                  subscriptionDetails.yearly_subscription_id,
                )
              }
            >
              <p className="text-2xl font-semibold">${yearlyCost}</p>
              <p className="text-gray-600">Yearly Subscription</p>
            </div>
          )}
        </div>

        <div className="mt-6 flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Enter promo code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="border border-gray-300 p-3 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
          />
          <button
            onClick={handlePromoCodeSubmit}
            className="bg-primary-500 text-white font-bold py-2 rounded-lg hover:bg-primary-600 transition"
          >
            Apply Promo Code
          </button>
          {promoMessage && (
            <p
              className={`text-sm ${isError ? 'text-red-500' : 'text-green-500'}`}
            >
              {promoMessage}
            </p>
          )}
        </div>

        <div className="mt-6 text-center">
          <p className="text-lg font-semibold">
            Transaction Amount: $
            {selectedPlan === 'monthly'
              ? monthlyCost
              : selectedPlan === 'yearly'
                ? yearlyCost
                : oneDayPassValue}
          </p>
          <p className="text-lg mt-2">
            This is a one-time payment and does not automatically renew.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="mt-6">
          <div className="flex items-center space-x-3 mb-4">
            <input
              type="radio"
              name="payment-method"
              value="credit-card"
              checked={selectedPaymentMethod === 'credit-card'}
              onChange={() => setSelectedPaymentMethod('credit-card')}
              className="h-5 w-5 text-primary-500"
            />
            <label className="text-gray-700 font-medium">Credit Card</label>
          </div>

          <div className="flex justify-center space-x-4 mb-6">
            <img src="/assets/images/amex.png" alt="Amex" className="w-12" />
            <img
              src="/assets/images/mastercard.png"
              alt="MasterCard"
              className="w-12"
            />
            <img src="/assets/images/visa.png" alt="Visa" className="w-12" />
          </div>

          {selectedPaymentMethod === 'credit-card' && (
            <div className="border border-gray-300 p-3 rounded-lg bg-gray-50">
              <CardElement className="p-2" />
            </div>
          )}

          <button
            type="submit"
            disabled={
              isLoading || !selectedSubscriptionId || !stripe || !elements
            }
            className="mt-6 bg-primary-500 text-white font-bold py-3 w-full rounded-lg hover:bg-primary-600 transition"
          >
            {isLoading ? 'Processing...' : 'Pay Now'}
          </button>

          {message && (
            <p
              className={`mt-4 text-center ${isError ? 'text-red-500' : 'text-green-500'}`}
            >
              {message}
            </p>
          )}
        </form>

        <div className="text-xs text-gray-500 text-center mt-6">
          Payments are final. If we experience outages, we will prorate refunds
          accordingly.
        </div>

        {isLoading && (
          <div className="absolute inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscribe;
