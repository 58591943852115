import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const DefenseVsPositionTDCheatSheet = () => {
  const navigate = useNavigate();
  const [tdData, setTdData] = useState([]);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const [expandedTeams, setExpandedTeams] = useState({});
  const [loading, setLoading] = useState(true);

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/td-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(response.data.td_trends_by_defense_vs_positions)) {
          setTdData(response.data.td_trends_by_defense_vs_positions);
        } else {
          setTdData([]);
        }
        setLoading(false);
      } catch (error) {
        handleError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const toggleTeamExpansion = (team, position) => {
    const key = `${team}-${position}`;
    setExpandedTeams((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;
  if (loading)
    return <LoadingSpinner text="Loading defense vs position metrics..." />;
  if (tdData.length === 0)
    return (
      <div className="text-center text-neutral-500">No data available.</div>
    );

  const groupedByGame = tdData.reduce((acc, item) => {
    acc[item.game_display_name] = acc[item.game_display_name] || [];
    acc[item.game_display_name].push(item);
    return acc;
  }, {});

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        Defense vs Position TD Cheat Sheet
      </h1>

      {Object.entries(groupedByGame).map(([game, trends]) => (
        <div
          key={game}
          className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
        >
          <div
            className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
            onClick={() => toggleGameExpansion(game)}
          >
            <span className="font-medium text-base sm:text-lg">
              {game} (View Trends)
            </span>
            {expandedGames[game] ? (
              <FiChevronUp className="w-5 h-5 text-white" />
            ) : (
              <FiChevronDown className="w-5 h-5 text-white" />
            )}
          </div>

          {expandedGames[game] && (
            <div className="p-4 space-y-4">
              {trends.map((td, index) => {
                const key = `${td.team_name}-${td.position_name}-${index}`;
                const teamKey = `${td.team_name}-${td.position_name}`;
                return (
                  <div
                    key={key}
                    className="border border-neutral-200 rounded-md"
                  >
                    <div
                      className="cursor-pointer flex justify-between items-center px-4 py-3 bg-neutral-100"
                      onClick={() =>
                        toggleTeamExpansion(td.team_name, td.position_name)
                      }
                    >
                      <span className="font-semibold text-[#484242]">
                        {td.team_name} - {td.position_name}
                      </span>
                      {expandedTeams[teamKey] ? (
                        <FiChevronUp className="w-4 h-4 text-[#484242]" />
                      ) : (
                        <FiChevronDown className="w-4 h-4 text-[#484242]" />
                      )}
                    </div>

                    {expandedTeams[teamKey] && (
                      <div className="px-4 py-3 text-sm text-neutral-700 space-y-2">
                        <div>Defense: {td.team_name}</div>
                        <div>Opposing Position: {td.position_name}</div>
                        <div>Games Played: {td.games_played}</div>

                        {td.position_name === 'Quarterback' && (
                          <>
                            <div>
                              Passing TDs Allowed: {td.passing_tds_allowed}
                            </div>
                            <div>
                              Games with 0 Passing TDs:{' '}
                              {td.games_with_0_passing_tds}
                            </div>
                            {td.qb_with_0_passing_tds?.trim() && (
                              <div>
                                QBs with 0 Passing TDs:{' '}
                                {td.qb_with_0_passing_tds}
                              </div>
                            )}
                            <div>
                              Games with 1 Passing TD:{' '}
                              {td.games_with_1_passing_td}
                            </div>
                            <div>
                              Games with 2+ Passing TDs:{' '}
                              {td.games_with_2plus_passing_tds}
                            </div>
                            {td.qb_with_2_plus_passing_tds?.trim() && (
                              <div>
                                QBs with 2+ Passing TDs:{' '}
                                {td.qb_with_2_plus_passing_tds}
                              </div>
                            )}
                          </>
                        )}

                        {td.position_name === 'Running Back' && (
                          <>
                            <div>ATDs Allowed: {td.atd_allowed}</div>
                            {td.rb_with_0_atd?.trim() && (
                              <div>
                                Notable RBs with 0 ATDs: {td.rb_with_0_atd}
                              </div>
                            )}
                            <div>Games with 0 ATDs: {td.games_with_0_atd}</div>
                            <div>Games with 1 ATD: {td.games_with_1_atd}</div>
                            <div>
                              Games with 2+ ATDs: {td.games_with_2plus_atd}
                            </div>
                            {td.rb_with_2_plus_atd?.trim() && (
                              <div>
                                Notable RBs with 2+ ATDs:{' '}
                                {td.rb_with_2_plus_atd}
                              </div>
                            )}
                          </>
                        )}

                        {td.position_name === 'Wide Receiver' && (
                          <>
                            <div>ATDs Allowed: {td.atd_allowed}</div>
                            {td.wr_with_0_atd?.trim() && (
                              <div>
                                Notable WRs with 0 ATDs: {td.wr_with_0_atd}
                              </div>
                            )}
                            <div>Games with 0 ATDs: {td.games_with_0_atd}</div>
                            <div>Games with 1 ATD: {td.games_with_1_atd}</div>
                            <div>
                              Games with 2+ ATDs: {td.games_with_2plus_atd}
                            </div>
                            {td.wr_with_2_plus_atd?.trim() && (
                              <div>
                                Notable WRs with 2+ ATDs:{' '}
                                {td.wr_with_2_plus_atd}
                              </div>
                            )}
                          </>
                        )}

                        {td.position_name === 'Tight End' && (
                          <>
                            <div>ATDs Allowed: {td.atd_allowed}</div>
                            {td.te_with_0_atd?.trim() && (
                              <div>
                                Notable TEs with 0 ATDs: {td.te_with_0_atd}
                              </div>
                            )}
                            <div>Games with 0 ATDs: {td.games_with_0_atd}</div>
                            <div>Games with 1 ATD: {td.games_with_1_atd}</div>
                            <div>
                              Games with 2+ ATDs: {td.games_with_2plus_atd}
                            </div>
                            {td.te_with_2_plus_atd?.trim() && (
                              <div>
                                Notable TEs with 2+ ATDs:{' '}
                                {td.te_with_2_plus_atd}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DefenseVsPositionTDCheatSheet;
