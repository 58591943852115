import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp, FiStar } from 'react-icons/fi';

const NBATrendHistoryCheatSheet = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [clusterData, setClusterData] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [showClusterModal, setShowClusterModal] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedGames, setExpandedGames] = useState({});

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
      setLoading(false);
    },
    [navigate],
  );

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const [gamesRes, trendsRes, clusterRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/current-games/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/prop-trends/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/cluster-report/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ]);

        setGames(gamesRes.data);
        setTrendData(trendsRes.data);
        setClusterData(clusterRes.data.nba_cluster_data || []);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchAllData();
  }, [handleError]);

  const doesClusterSupportTrend = (
    playerName,
    opposingTeam,
    metric,
    overUnder,
    direction,
  ) => {
    const lowerMetric = metric.toLowerCase();

    return clusterData.some((entry) => {
      const players = entry.player_list
        ?.toLowerCase()
        .split(', ')
        .map((p) => p.trim());
      const isPlayerInCluster = players?.includes(playerName.toLowerCase());
      const isAgainstSameDefense =
        entry.opposing_defense?.toLowerCase() === opposingTeam?.toLowerCase();

      if (!isPlayerInCluster || !isAgainstSameDefense) return false;

      const avgValue = parseFloat(entry[`avg_${lowerMetric}`]);
      if (isNaN(avgValue)) return false;

      if (direction === 'Over' && avgValue > overUnder) return true;
      if (direction === 'Under' && avgValue < overUnder) return true;

      return false;
    });
  };

  const handleClusterClick = (playerName, opposingTeam) => {
    const match = clusterData.find((entry) => {
      const players = entry.player_list
        ?.toLowerCase()
        .split(', ')
        .map((p) => p.trim());
      return (
        players?.includes(playerName.toLowerCase()) &&
        entry.opposing_defense?.toLowerCase() === opposingTeam?.toLowerCase()
      );
    });
    if (match) {
      setSelectedCluster(match);
      setShowClusterModal(true);
    }
  };

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  if (loading) return <LoadingSpinner text="Report generating..." />;
  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      <div className="flex justify-center mb-4">
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="SportBetData Logo"
          className="h-36 object-contain"
        />
      </div>

      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        NBA Prop Trend History Cheat Sheet
      </h1>

      <div className="flex flex-wrap gap-6 justify-center items-center mb-6 text-sm text-gray-600">
        <div className="flex items-center gap-2">
          <FiStar className="text-yellow-500" />
          <span>Player has an active trend supported by cluster data!</span>
        </div>
      </div>

      {games.length > 0 ? (
        games.map((game) => (
          <div
            key={game.game_id}
            className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
          >
            <div
              className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-medium text-base sm:text-lg">
                {game.game_display_name} (View Trends)
              </span>
              {expandedGames[game.game_id] ? (
                <FiChevronUp className="w-5 h-5 text-white" />
              ) : (
                <FiChevronDown className="w-5 h-5 text-white" />
              )}
            </div>

            {expandedGames[game.game_id] && (
              <div className="p-6 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {trendData
                  .filter(
                    (trend) => String(trend.game_id) === String(game.game_id),
                  )
                  .sort((a, b) => a.player_name.localeCompare(b.player_name))
                  .map((trend) => (
                    <div
                      key={`${trend.player_name}-${trend.metric}`}
                      className="border border-neutral-200 rounded-lg p-4 bg-white hover:shadow-md transition"
                    >
                      <div className="font-semibold text-[#484242] text-base mb-2 flex items-center gap-2">
                        {trend.player_name}
                        {doesClusterSupportTrend(
                          trend.player_name,
                          trend.opposing_team,
                          trend.metric,
                          trend.over_under,
                          trend.streak_direction,
                        ) && (
                          <FiStar
                            className="text-yellow-500 cursor-pointer"
                            title="Cluster supports this trend"
                            onClick={() =>
                              handleClusterClick(
                                trend.player_name,
                                trend.opposing_team,
                              )
                            }
                          />
                        )}
                      </div>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric:</span>{' '}
                        {trend.metric} (O/U {trend.over_under})
                      </p>
                      <p className="text-sm text-neutral-700">
                        <span className="font-medium">Current Streak:</span>{' '}
                        {trend.current_streak} ({trend.streak_direction})
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center text-neutral-500">No games available</div>
      )}

      {showClusterModal && selectedCluster && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
            <button
              className="absolute top-2 right-3 text-gray-500 hover:text-black text-xl"
              onClick={() => setShowClusterModal(false)}
            >
              &times;
            </button>
            <h2 className="text-lg font-bold mb-4 text-[#484242]">
              Cluster Stats vs {selectedCluster.opposing_defense}
            </h2>
            <div className="text-sm text-gray-700 space-y-2">
              <p>
                <strong>Players:</strong> {selectedCluster.player_list}
              </p>
              <p>
                <strong>Avg Points:</strong> {selectedCluster.avg_points}
              </p>
              <p>
                <strong>Avg Threes:</strong> {selectedCluster.avg_3pm}
              </p>
              <p>
                <strong>Avg Assists:</strong> {selectedCluster.avg_assists}
              </p>
              <p>
                <strong>Avg Rebounds:</strong> {selectedCluster.avg_rebounds}
              </p>
              <p>
                <strong>Avg Steals:</strong> {selectedCluster.avg_steals}
              </p>
              <p>
                <strong>Avg Blocks:</strong> {selectedCluster.avg_blocks}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NBATrendHistoryCheatSheet;
