import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp, FiStar, FiInfo } from 'react-icons/fi';

const NBATripleDouble = () => {
  const [tripleDoubles, setTripleDoubles] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const [clusterData, setClusterData] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [showClusterModal, setShowClusterModal] = useState(false);

  const handleError = useCallback((error) => {
    if (error.response) {
      setError(`An unexpected error occurred: ${error.response.statusText}`);
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);
  }, []);

  const isClusterSupported = (playerName, opposingTeam) => {
    return clusterData.some((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;

      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());
      const isPlayerInCluster = players.includes(playerName.toLowerCase());
      const isAgainstSameDefense =
        entry.opposing_defense.toLowerCase() === opposingTeam?.toLowerCase();

      const statValues = [
        parseFloat(entry.avg_points),
        parseFloat(entry.avg_rebounds),
        parseFloat(entry.avg_assists),
        parseFloat(entry.avg_steals),
        parseFloat(entry.avg_blocks),
      ];

      const qualifyingStats = statValues.filter(
        (val) => !isNaN(val) && val >= 10,
      );

      return (
        isPlayerInCluster && isAgainstSameDefense && qualifyingStats.length >= 3
      );
    });
  };

  const isInClusterReport = (playerName, opposingTeam) => {
    return clusterData.some((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;

      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());
      return (
        players.includes(playerName.toLowerCase()) &&
        entry.opposing_defense.toLowerCase() === opposingTeam?.toLowerCase()
      );
    });
  };

  const handleIconClick = (playerName, opposingTeam) => {
    const matchingEntry = clusterData.find((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;
      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());
      return (
        players.includes(playerName.toLowerCase()) &&
        entry.opposing_defense.toLowerCase() === opposingTeam.toLowerCase()
      );
    });
    if (matchingEntry) {
      setSelectedCluster(matchingEntry);
      setShowClusterModal(true);
    }
  };

  useEffect(() => {
    const fetchTripleDoubleReport = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const [gamesRes, reportRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/current-games/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/potential-triple-doubles/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ]);

        setGames(gamesRes.data);
        setTripleDoubles(reportRes.data.nba_predicted_triple_doubles || []);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchTripleDoubleReport();
  }, [handleError]);

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/cluster-report/`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setClusterData(response.data.nba_cluster_data || []);
      } catch (error) {
        console.error('Cluster fetch failed:', error);
      }
    };

    fetchClusterData();
  }, []);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const getStatLine = (player) => {
    const statPairs = [
      ['Points', player.predicted_points],
      ['Rebounds', player.predicted_rebounds],
      ['Assists', player.predicted_assists],
      ['Steals', player.predicted_steals],
      ['Blocks', player.predicted_blocks],
    ];
    return statPairs
      .filter(([_, val]) => val >= 6.5)
      .map(([label, val]) => `${label}: ${val}`)
      .join(' | ');
  };

  if (loading)
    return <LoadingSpinner text="Loading Potential Triple Doubles..." />;
  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      <div className="flex justify-center mb-4">
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="SportBetData Logo"
          className="h-36 object-contain"
        />
      </div>
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        NBA Potential Triple Doubles
      </h1>

      <div className="flex flex-wrap gap-6 justify-center items-center mb-6 text-sm text-gray-600">
        <div className="flex items-center gap-2">
          <FiStar className="text-yellow-500" />
          <span>
            Qualifies for triple double in cluster report and predicted for
            triple double.
          </span>
        </div>
        <div className="flex items-center gap-2">
          <FiInfo className="text-blue-500" />
          <span>In cluster report, click to view details.</span>
        </div>
      </div>

      {games.length > 0 ? (
        games.map((game) => (
          <div
            key={game.game_id}
            className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
          >
            <div
              className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-medium text-base sm:text-lg">
                {game.game_display_name}
              </span>
              {expandedGames[game.game_id] ? (
                <FiChevronUp className="w-5 h-5 text-white" />
              ) : (
                <FiChevronDown className="w-5 h-5 text-white" />
              )}
            </div>

            {expandedGames[game.game_id] && (
              <div className="p-6 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {tripleDoubles
                  .filter(
                    (item) => String(item.game_id) === String(game.game_id),
                  )
                  .map((player, index) => (
                    <div
                      key={index}
                      className="border border-neutral-200 rounded-lg p-4 bg-white hover:shadow-md transition"
                    >
                      <div className="font-semibold text-[#484242] text-base mb-2 flex items-center gap-2">
                        {player.player_name}
                        {isClusterSupported(
                          player.player_name,
                          player.opposing_team,
                        ) ? (
                          <FiStar
                            className="text-yellow-500 cursor-pointer"
                            title="Cluster Supported"
                            onClick={() =>
                              handleIconClick(
                                player.player_name,
                                player.opposing_team,
                              )
                            }
                          />
                        ) : isInClusterReport(
                            player.player_name,
                            player.opposing_team,
                          ) ? (
                          <FiInfo
                            className="text-blue-500 cursor-pointer"
                            title="In Cluster Report (Click for details)"
                            onClick={() =>
                              handleIconClick(
                                player.player_name,
                                player.opposing_team,
                              )
                            }
                          />
                        ) : null}
                      </div>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Team:</span>{' '}
                        {player.player_team}
                      </p>
                      <p className="text-sm text-neutral-700">
                        <span className="font-medium">Likely Categories:</span>{' '}
                        {getStatLine(player)}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center text-neutral-500">No games available</div>
      )}

      {showClusterModal && selectedCluster && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
            <button
              className="absolute top-2 right-3 text-gray-500 hover:text-black text-xl"
              onClick={() => setShowClusterModal(false)}
            >
              &times;
            </button>
            <h2 className="text-lg font-bold mb-4 text-[#484242]">
              Cluster Stats vs {selectedCluster.opposing_defense}
            </h2>
            <div className="text-sm text-gray-700 space-y-2">
              <p>
                <strong>Players:</strong> {selectedCluster.player_list}
              </p>
              <p>
                <strong>Avg Points:</strong> {selectedCluster.avg_points}
              </p>
              <p>
                <strong>Avg Threes:</strong> {selectedCluster.avg_3pm}
              </p>
              <p>
                <strong>Avg Assists:</strong> {selectedCluster.avg_assists}
              </p>
              <p>
                <strong>Avg Rebounds:</strong> {selectedCluster.avg_rebounds}
              </p>
              <p>
                <strong>Avg Steals:</strong> {selectedCluster.avg_steals}
              </p>
              <p>
                <strong>Avg Blocks:</strong> {selectedCluster.avg_blocks}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NBATripleDouble;
