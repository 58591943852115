import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import LoadingSpinner from '../common/LoadingSpinner';
import Button from '../common/Button';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const NBAGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [reverseCorrelationData, setReverseCorrelationData] = useState([]);
  const [nbaClusterData, setNbaClusterData] = useState([]);
  const [isCheatSheetView, setIsCheatSheetView] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You do not have an active subscription.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  const formatReverseCorrelationBet = (
    metric1,
    metric2,
    odds1,
    odds2,
    predicted1,
    predicted2,
  ) => {
    const bet1 =
      predicted1 > odds1
        ? `Over ${odds1} ${metric1}`
        : `Under ${odds1} ${metric1}`;
    const bet2 =
      predicted2 > odds2
        ? `Over ${odds2} ${metric2}`
        : `Under ${odds2} ${metric2}`;
    return `${bet1} & ${bet2}`;
  };

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/1/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (permissionResponse.status === 200) {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setGameData(response.data);

          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, navigate, handleError]);

  useEffect(() => {
    const fetchTrendData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const trendsResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/prop-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        const filteredTrendData = trendsResponse.data.filter(
          (trend) => String(trend.game_id) === String(game_id),
        );
        setTrendData(filteredTrendData);
      } catch (error) {
        handleError(error);
      }
    };

    if (isCheatSheetView) {
      fetchTrendData();
    }
  }, [isCheatSheetView, game_id, handleError]);

  useEffect(() => {
    const fetchReverseCorrelationData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/reverse-correlations/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        const filteredCorrelationData =
          response.data.nba_reverse_correlations.filter(
            (correlation) => String(correlation.game_id) === String(game_id),
          );

        setReverseCorrelationData(filteredCorrelationData);
      } catch (error) {
        handleError(error);
      }
    };

    if (isCheatSheetView) {
      fetchReverseCorrelationData();
    }
  }, [isCheatSheetView, game_id, handleError]);

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/cluster-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (response.data && response.data.nba_cluster_data) {
          setNbaClusterData(response.data.nba_cluster_data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchClusterData();
  }, [handleError]);

  const toggleExpansion = (teamName, period) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [`${teamName}-${period}`]: !prevExpandedSections[`${teamName}-${period}`],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!gameData) {
    return <LoadingSpinner text="Loading game data..." />;
  }

  const {
    games,
    game_summary,
    player_predictions,
    recent_performances,
    odd_comparison,
    player_odds,
  } = gameData;

  const selectedGame = games.find(
    (game) => game.game_id.toString() === game_id,
  );
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team };
  const homeTeam = { name: selectedGame.home_team };

  const awayTeamImage = selectedGame.away_team_image_binary;
  const homeTeamImage = selectedGame.home_team_image_binary;

  const awayTeamSummary = game_summary.find(
    (summary) => summary.player_team === awayTeam.name,
  );
  const homeTeamSummary = game_summary.find(
    (summary) => summary.player_team === homeTeam.name,
  );

  const awayTeamPoints = awayTeamSummary
    ? parseFloat(awayTeamSummary.team_points)
    : 0;
  const homeTeamPoints = homeTeamSummary
    ? parseFloat(homeTeamSummary.team_points)
    : 0;
  let totalPoints = awayTeamPoints + homeTeamPoints;
  totalPoints = parseFloat(totalPoints.toFixed(2));

  const mapPlayerOdds = (playerName, playerOdds) => {
    return playerOdds
      .filter((odd) => odd.player_name === playerName)
      .map((odd) => ({
        metric: odd.metric,
        overUnder: odd.over_under,
        bettingOver: parseFloat(odd.betting_100_on_over_would_return),
        bettingUnder: parseFloat(odd.betting_100_on_under_would_return),
      }));
  };

  const createChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      Points: 'points',
      Rebounds: 'rebounds',
      Assists: 'assists',
      Threes: 'threePointMade',
      'Points rebounds assists': 'Points rebounds assists',
    };

    const metricKey = metricKeyMap[metric];
    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => {
        if (metric === 'Points rebounds assists') {
          return (
            parseFloat(p.points) +
            parseFloat(p.rebounds) +
            parseFloat(p.assists)
          );
        }
        return parseFloat(p[metricKey]);
      })
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const predictionValue = parseFloat(
      predictions.find((p) => p.metric === metric).value,
    );

    const vegasLine = playerOdds.find(
      (odd) => odd.metric === metric,
    )?.overUnder;
    const vegasLineValue = vegasLine ? parseFloat(vegasLine) : null;

    return {
      labels: sortedPerformances.map((p) => p.date),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues,
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 6.5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data:
            vegasLineValue !== null
              ? Array(recentPerformanceValues.length).fill(vegasLineValue)
              : [],
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        ticks: {
          color: '#6B7280',
          font: { size: 10 },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          color: '#6B7280',
          font: { size: 10 },
        },
        grid: {
          color: '#E5E7EB',
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        backgroundColor: '#111827',
        titleFont: { size: 12 },
        bodyFont: { size: 12 },
        padding: 10,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };

  const awayPlayers = player_predictions
    .filter(
      (player) =>
        player.player_team === selectedGame.away_team &&
        player.predicted_minutes > 1,
    )
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: 'Threes', value: player.predicted_three_point_made },
        {
          metric: 'Points rebounds assists',
          value: player.predicted_points_rebound_assist,
        },
      ],
      recentPerformances: recent_performances
        .filter(
          (performance) =>
            performance.player === player.player_name &&
            performance.player_team === selectedGame.away_team,
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds),
    }));

  const homePlayers = player_predictions
    .filter(
      (player) =>
        player.player_team === selectedGame.home_team &&
        player.predicted_minutes > 1,
    )
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: 'Threes', value: player.predicted_three_point_made },
        {
          metric: 'Points rebounds assists',
          value: player.predicted_points_rebound_assist,
        },
      ],
      recentPerformances: recent_performances
        .filter(
          (performance) =>
            performance.player === player.player_name &&
            performance.player_team === selectedGame.home_team,
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds),
    }));

  const renderNBAClusterReport = () => {
    const gamePlayers = new Set(
      [...awayPlayers, ...homePlayers].map((player) => player.name),
    );

    const filteredClusterData = nbaClusterData
      .filter(
        (item) =>
          item.opposing_defense === awayTeam.name ||
          item.opposing_defense === homeTeam.name,
      )
      .map((item) => {
        const filteredPlayers = item.player_list
          .split(', ')
          .filter((player) => gamePlayers.has(player))
          .join(', ');

        return {
          ...item,
          player_list: filteredPlayers,
        };
      })
      .filter((item) => item.player_list.length > 0);

    return (
      <>
        <h2 className="text-2xl font-heading text-primary mb-4">
          NBA Cluster Report
        </h2>
        <p className="text-sm text-neutral-500 mb-6">
          Only showing relevant players in cluster. Based on games where players
          played 25 minutes or more.
        </p>
        <div className="overflow-x-auto rounded-lg shadow-md">
          <table className="min-w-full divide-y divide-neutral-200">
            <thead className="bg-neutral-100">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Opposing Defense
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  PPG Cluster
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Position
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Points
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Assists
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Rebounds
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Threes
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Steals
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Blocks
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Avg Turnovers
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-neutral-700">
                  Cluster Players
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-neutral-200 text-sm">
              {filteredClusterData.map((metric, index) => (
                <tr key={index} className="hover:bg-neutral-50 transition">
                  <td className="px-4 py-2 text-neutral-900">
                    {metric.opposing_defense}
                  </td>
                  <td className="px-4 py-2">{metric.ppg_cluster}</td>
                  <td className="px-4 py-2">{metric.position_name}</td>
                  <td className="px-4 py-2">{metric.avg_points}</td>
                  <td className="px-4 py-2">{metric.avg_assists}</td>
                  <td className="px-4 py-2">{metric.avg_rebounds}</td>
                  <td className="px-4 py-2">{metric.avg_3pm}</td>
                  <td className="px-4 py-2">{metric.avg_steals}</td>
                  <td className="px-4 py-2">{metric.avg_blocks}</td>
                  <td className="px-4 py-2">{metric.avg_turnovers}</td>
                  <td className="px-4 py-2">{metric.player_list}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderTeamMetricsDropdown = (teamName) => {
    const periodOrder = {
      last_7_days: 0,
      last_15_days: 1,
      last_30_days: 2,
      season: 3,
    };

    const periodLabels = {
      last_7_days: 'Last 7',
      last_15_days: 'Last 15',
      last_30_days: 'Last 30',
      season: 'Season',
    };

    const filteredMetrics = teamMetricsData
      .filter((metric) => metric.team_name === teamName)
      .sort((a, b) => periodOrder[a.period] - periodOrder[b.period]);

    return (
      <div className="flex flex-wrap gap-4">
        {filteredMetrics.map((metric, index) => {
          const key = `${metric.team_name}-${metric.period}`;
          const label = periodLabels[metric.period] || 'Season';

          return (
            <div key={index} className="w-full sm:w-auto">
              <Button
                onClick={() => toggleExpansion(metric.team_name, metric.period)}
                variant="outlinedPrimary"
                size="slimMedium"
                className="w-full"
              >
                {label}
              </Button>

              {expandedSections[key] && (
                <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mt-2 text-sm space-y-2 shadow-sm">
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Points
                    </span>
                    <span className="text-primary font-semibold">
                      {metric.avg_points}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Threes
                    </span>
                    <span className="text-primary">
                      {metric.avg_threes_per_game}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Assists
                    </span>
                    <span className="text-primary">{metric.avg_assists}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Rebounds
                    </span>
                    <span className="text-primary">{metric.avg_rebounds}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Points Allowed
                    </span>
                    <span className="text-danger font-semibold">
                      {metric.avg_points_allowed}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="game-details">
      <div className="flex flex-col gap-4 mb-6">
        <div className="flex">
          <Button
            onClick={() => setIsCheatSheetView(!isCheatSheetView)}
            variant="primary"
            size="slimMedium"
          >
            {isCheatSheetView ? 'Show Detailed View' : 'Show Cheat Sheet'}
          </Button>
        </div>

        {awayTeamImage && homeTeamImage && (
          <div className="flex justify-center items-center gap-8">
            <img
              src={`data:image/png;base64,${awayTeamImage}`}
              alt={`${awayTeam.name} logo`}
              className="max-w-[120px] max-h-[120px] object-contain"
            />
            <img
              src={`data:image/png;base64,${homeTeamImage}`}
              alt={`${homeTeam.name} logo`}
              className="max-w-[120px] max-h-[120px] object-contain"
            />
          </div>
        )}
      </div>

      {isCheatSheetView ? (
        <div className="w-full space-y-10">
          <div>{renderNBAClusterReport()}</div>

          {trendData.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-heading text-primary">
                Trend History Report – Streaks 5 or Greater
              </h2>
              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {trendData.map((trend, index) => (
                  <div
                    key={index}
                    className="bg-white shadow rounded-lg p-4 text-sm border border-gray-100"
                  >
                    <p className="font-semibold text-neutral-700">
                      {trend.player_name}
                    </p>
                    <p className="text-neutral-600">
                      {trend.metric}: O/U {trend.over_under}
                    </p>
                    <p className="text-neutral-600">
                      Current Streak: {trend.current_streak} (
                      {trend.streak_direction})
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {reverseCorrelationData.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-heading text-primary">
                Inverse Correlation Report
              </h2>
              <div className="grid gap-6 md:grid-cols-2">
                {reverseCorrelationData.map((correlation, index) => (
                  <div
                    key={index}
                    className="bg-white border border-gray-100 rounded-lg shadow p-4 text-sm space-y-2"
                  >
                    <p className="font-semibold text-neutral-700">
                      Player: {correlation.player_name}
                    </p>
                    <p className="text-neutral-600">
                      Metric 1: {correlation.metric1} (O/U: {correlation.odds1})
                    </p>
                    <p className="text-neutral-600">
                      Metric 2: {correlation.metric2} (O/U: {correlation.odds2})
                    </p>
                    <p className="text-neutral-600">
                      Prediction 1: {correlation.predicted1}{' '}
                      {correlation.metric1}
                    </p>
                    <p className="text-neutral-600">
                      Prediction 2: {correlation.predicted2}{' '}
                      {correlation.metric2}
                    </p>
                    <p className="text-danger font-medium">
                      Bet:{' '}
                      {formatReverseCorrelationBet(
                        correlation.metric1,
                        correlation.metric2,
                        correlation.odds1,
                        correlation.odds2,
                        correlation.predicted1,
                        correlation.predicted2,
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div id="game-details-content" className="p-6">
          {/* Game Display Ribbon */}
          <div className="bg-neutral text-white text-center py-4 rounded-xl text-2xl font-heading mb-6">
            {selectedGame.game_display_name}
          </div>

          {/* Game Summary + Vegas Odds Side-by-Side on Desktop */}
          <div className="flex flex-col lg:flex-row justify-center gap-6 mb-10">
            {/* Game Summary */}
            <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:max-w-md">
              <h2 className="text-xl font-heading text-primary mb-4">
                Game Predicted Summary
              </h2>
              <p className="text-base text-neutral mb-2">
                {awayTeam.name} Projected Total Points:{' '}
                <span className="font-semibold">{awayTeamPoints}</span>
              </p>
              <p className="text-base text-neutral mb-2">
                {homeTeam.name} Projected Total Points:{' '}
                <span className="font-semibold">{homeTeamPoints}</span>
              </p>
              <p className="text-base text-neutral mb-2">
                Point Differential:{' '}
                <span className="font-semibold">
                  {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)}
                </span>{' '}
                in favor of{' '}
                <span className="font-semibold">
                  {awayTeamPoints > homeTeamPoints
                    ? awayTeam.name
                    : homeTeam.name}
                </span>
              </p>
              <p className="text-base text-neutral">
                Total Points:{' '}
                <span className="font-semibold">{totalPoints}</span>
              </p>
            </div>

            {/* Vegas Odds */}
            {odd_comparison?.[0] &&
              odd_comparison[0].vegas_predicted_total !== null &&
              odd_comparison[0].vegas_predicted_winner_spread !== null && (
                <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:max-w-md">
                  <h2 className="text-xl font-heading text-primary mb-4">
                    Vegas Odds
                  </h2>
                  <p className="text-base text-neutral mb-2">
                    Vegas Predicted Winner:{' '}
                    <span className="font-semibold">
                      {odd_comparison[0].vegas_predicted_winner ?? 'N/A'}
                    </span>
                  </p>
                  <p className="text-base text-neutral mb-2">
                    Vegas Predicted Total:{' '}
                    <span className="font-semibold">
                      {odd_comparison[0].vegas_predicted_total}
                    </span>
                  </p>
                  <p className="text-base text-neutral">
                    Vegas Winner Spread:{' '}
                    <span className="font-semibold">
                      {odd_comparison[0].vegas_predicted_winner_spread}
                    </span>
                  </p>
                </div>
              )}
          </div>

          {/* Team Metrics Dropdowns */}
          <div className="mb-12 flex flex-col lg:flex-row justify-center items-start gap-12">
            <div className="w-full lg:w-1/2">
              <h2 className="text-xl font-heading text-primary mb-3">
                {awayTeam.name} Team Metrics
              </h2>
              {renderTeamMetricsDropdown(awayTeam.name)}
            </div>
            <div className="w-full lg:w-1/2">
              <h2 className="text-xl font-heading text-primary mb-3">
                {homeTeam.name} Team Metrics
              </h2>
              {renderTeamMetricsDropdown(homeTeam.name)}
            </div>
          </div>

          {/* Away Team */}
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-4">
            {awayTeam.name}
          </div>
          <div className="space-y-10 mb-12">
            {awayPlayers.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Predictions */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      <div className="h-40 w-full">
                        <Line
                          data={createChartData(
                            player.predictions,
                            player.recentPerformances,
                            player.playerOdds,
                            prediction.metric,
                          )}
                          options={chartOptions}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Home Team */}
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-4">
            {homeTeam.name}
          </div>
          <div className="space-y-10 mb-10">
            {homePlayers.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Predictions */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      <div className="h-40 w-full">
                        <Line
                          data={createChartData(
                            player.predictions,
                            player.recentPerformances,
                            player.playerOdds,
                            prediction.metric,
                          )}
                          options={chartOptions}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NBAGameDetails;
