import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getNBAGameData,
  getNBATeamMetrics,
  getNBATrendData,
  getNBAReverseCorrelationData,
  getNBAPositionMetrics,
  getNBAClusterData,
} from '../../api/nbaApi';

// **Async Thunks**

export const fetchNBAGameData = createAsyncThunk(
  'nba/fetchNBAGameData',
  async ({ date, gameId }, { rejectWithValue }) => {
    try {
      return await getNBAGameData(date, gameId);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchNBATeamMetrics = createAsyncThunk(
  'nba/fetchNBATeamMetrics',
  async (date, { rejectWithValue }) => {
    try {
      return await getNBATeamMetrics(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchNBATrendData = createAsyncThunk(
  'nba/fetchNBATrendData',
  async (_, { rejectWithValue }) => {
    try {
      return await getNBATrendData();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchNBAReverseCorrelationData = createAsyncThunk(
  'nba/fetchNBAReverseCorrelationData',
  async (_, { rejectWithValue }) => {
    try {
      return await getNBAReverseCorrelationData();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchNBAPositionMetrics = createAsyncThunk(
  'nba/fetchNBAPositionMetrics',
  async (date, { rejectWithValue }) => {
    try {
      return await getNBAPositionMetrics(date);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchNBAClusterData = createAsyncThunk(
  'nba/fetchNBAClusterData',
  async (_, { rejectWithValue }) => {
    try {
      return await getNBAClusterData();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// **Initial State**
const initialState = {
  gameData: null,
  teamMetrics: [],
  trendData: [],
  reverseCorrelationData: [],
  positionMetrics: [],
  nbaClusterData: [],
  isLoading: false,
  error: null,
};

// **Slice**
const nbaSlice = createSlice({
  name: 'nba',
  initialState,
  reducers: {
    clearNBAState(state) {
      state.gameData = null;
      state.teamMetrics = [];
      state.trendData = [];
      state.reverseCorrelationData = [];
      state.positionMetrics = [];
      state.nbaClusterData = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // **NBA Game Data**
      .addCase(fetchNBAGameData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBAGameData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameData = action.payload;
      })
      .addCase(fetchNBAGameData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **NBA Team Metrics**
      .addCase(fetchNBATeamMetrics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBATeamMetrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teamMetrics = action.payload;
      })
      .addCase(fetchNBATeamMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **NBA Trend Data**
      .addCase(fetchNBATrendData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBATrendData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.trendData = action.payload;
      })
      .addCase(fetchNBATrendData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **NBA Reverse Correlation Data**
      .addCase(fetchNBAReverseCorrelationData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBAReverseCorrelationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reverseCorrelationData = action.payload;
      })
      .addCase(fetchNBAReverseCorrelationData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **NBA Position Metrics**
      .addCase(fetchNBAPositionMetrics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBAPositionMetrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.positionMetrics = action.payload;
      })
      .addCase(fetchNBAPositionMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // **NBA Cluster Data**
      .addCase(fetchNBAClusterData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNBAClusterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nbaClusterData = action.payload;
      })
      .addCase(fetchNBAClusterData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// **Export Actions and Reducer**
export const { clearNBAState } = nbaSlice.actions;
export default nbaSlice.reducer;
