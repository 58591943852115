// ErrorPage.js
import React from 'react';

const ErrorPage = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>Something Went Wrong</h1>
    <p>We encountered an issue while processing your request. Please try again later.</p>
    <a href="/dashboard">Go to Home</a>
  </div>
);

export default ErrorPage;
