import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const WNBATrendHistoryCheatSheet = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedGames, setExpandedGames] = useState({});

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
      setLoading(false);
    },
    [navigate],
  );

  useEffect(() => {
    const fetchGamesAndTrends = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const [gamesRes, trendsRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/current-games/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/prop-trends/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ]);

        setGames(gamesRes.data);
        setTrendData(trendsRes.data);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchGamesAndTrends();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  if (loading) return <LoadingSpinner text="Loading WNBA Prop Trends..." />;
  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      {/* Logo */}
      <div className="flex justify-center mb-4">
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="SportBetData Logo"
          className="h-36 object-contain"
        />
      </div>

      {/* Title */}
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        WNBA Prop Trend History Cheat Sheet
      </h1>

      {/* Game Trend Sections */}
      {games.length > 0 ? (
        games.map((game) => (
          <div
            key={game.game_id}
            className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
          >
            <div
              className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-medium text-base sm:text-lg">
                {game.game_display_name} (View Trends)
              </span>
              {expandedGames[game.game_id] ? (
                <FiChevronUp className="w-5 h-5 text-white" />
              ) : (
                <FiChevronDown className="w-5 h-5 text-white" />
              )}
            </div>

            {expandedGames[game.game_id] && (
              <div className="p-6 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {trendData
                  .filter(
                    (trend) => String(trend.game_id) === String(game.game_id),
                  )
                  .sort((a, b) => a.player_name.localeCompare(b.player_name))
                  .map((trend) => (
                    <div
                      key={`${trend.player_name}-${trend.metric}`}
                      className="border border-neutral-200 rounded-lg p-4 bg-white hover:shadow-md transition"
                    >
                      <div className="font-semibold text-[#484242] text-base mb-2">
                        {trend.player_name}
                      </div>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric:</span>{' '}
                        {trend.metric} (O/U {trend.over_under})
                      </p>
                      <p className="text-sm text-neutral-700">
                        <span className="font-medium">Current Streak:</span>{' '}
                        {trend.current_streak} ({trend.streak_direction})
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center text-neutral-500">No games available</div>
      )}
    </div>
  );
};

export default WNBATrendHistoryCheatSheet;
