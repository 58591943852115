import apiClient, { buildApiUrl } from './apiClient';
import { handleError } from '../utils/errorHandler';

/**
 * Fetch NBA game data for a specific date and game ID.
 * @param {string} date - The date of the game.
 * @param {string} gameId - The ID of the game.
 * @returns {Promise<Object>} - The game data for the given date and game ID.
 * @throws {Error} - If the API call fails.
 */
export const getNBAGameData = async (date, gameId) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/pdf-data/${date}/${gameId}/`),
    );

    const {
      game_summary,
      player_predictions,
      recent_performances,
      odd_comparison,
      player_odds,
    } = response;

    return {
      game_summary,
      player_predictions,
      recent_performances,
      odd_comparison,
      player_odds,
    };
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA team metrics for a specific date.
 * @param {string} date - The date for which to retrieve team metrics.
 * @returns {Promise<Array>} - A list of team metrics for the given date.
 * @throws {Error} - If the API call fails.
 */
export const getNBATeamMetrics = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/team-metrics/${date}/`),
    );

    return response || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA trend data for player prop trends.
 * @returns {Promise<Array>} - A list of NBA prop trend data.
 * @throws {Error} - If the API call fails.
 */
export const getNBATrendData = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/prop-trends/`),
    );

    return response || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA reverse correlation data for games.
 * @returns {Promise<Array>} - A list of NBA reverse correlation data.
 * @throws {Error} - If the API call fails.
 */
export const getNBAReverseCorrelationData = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/reverse-correlations/`),
    );

    return response.nba_reverse_correlations || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA position metrics data for defense vs positions.
 * @param {string} date - The date for which to retrieve position metrics.
 * @returns {Promise<Array>} - A list of position metrics.
 * @throws {Error} - If the API call fails.
 */
export const getNBAPositionMetrics = async (date) => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/position-metrics-defense/`),
    );

    return response.position_metrics_by_defense_vs_positions || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA cluster report data.
 * @returns {Promise<Array>} - A list of NBA cluster report data.
 * @throws {Error} - If the API call fails.
 */
export const getNBAClusterData = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/cluster-report/`),
    );

    return response.nba_cluster_data || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA potential double double data.
 * @returns {Promise<Array>} - A list of potential double doubles.
 * @throws {Error} - If the API call fails.
 */
export const getNBAPotentialDoubleDoubles = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/potential-double-doubles/`),
    );

    return response.nba_predicted_double_doubles || [];
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch NBA potential triple double data.
 * @returns {Promise<Array>} - A list of potential triple doubles.
 * @throws {Error} - If the API call fails.
 */
export const getNBAPotentialTripleDoubles = async () => {
  try {
    const response = await apiClient.get(
      buildApiUrl('data', `nba/potential-triple-doubles/`),
    );

    return response.nba_predicted_triple_doubles || [];
  } catch (error) {
    handleError(error);
  }
};
