import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';

const NBAPlayerDAvgReport = () => {
  const [playerData, setPlayerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [opposingTeamList, setOpposingTeamList] = useState([]);
  const [selectedOpposingTeam, setSelectedOpposingTeam] = useState('');
  const [playerNameFilter, setPlayerNameFilter] = useState('');
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [visibleColumns, setVisibleColumns] = useState({
    opposingTeam: true,
    avgPointsAllowed: true,
    avgAssistsAllowed: true,
    avgReboundsAllowed: true,
    avg3PMAllowed: true,
    avgStealsAllowed: true,
    avgBlocksAllowed: true,
    avgTurnoversAllowed: true,
    playerList: true,
  });
  const [showFilteredPlayers, setShowFilteredPlayers] = useState(false);
  const [minimizedRows, setMinimizedRows] = useState(new Set());

  // Fetch player defensive average report data and player-team map
  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/player-d-avg-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(response.data.nba_player_average_report)) {
          setPlayerData(response.data.nba_player_average_report);
          setFilteredData(response.data.nba_player_average_report);

          // Extract unique opposing teams for the dropdown
          const uniqueOpposingTeams = [
            ...new Set(
              response.data.nba_player_average_report.map(
                (item) => item.opposing_team,
              ),
            ),
          ];
          setOpposingTeamList(uniqueOpposingTeams);
        } else {
          setPlayerData([]);
          setFilteredData([]);
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching player defensive averages.');
        setLoading(false);
      }
    };

    const fetchTeamData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const teamResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/player-team-map/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(teamResponse.data.nba_player_teams)) {
          setTeamList(teamResponse.data.nba_player_teams);
        } else {
          setTeamList([]);
        }
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchPlayerData();
    fetchTeamData();
  }, []);

  // Handle filtering the data by opposing team, player name, and selected team
  const handleFilterChange = () => {
    let filtered = playerData;

    // Filter by selected opposing team
    if (selectedOpposingTeam) {
      filtered = filtered.filter(
        (item) =>
          item.opposing_team.toLowerCase() ===
          selectedOpposingTeam.toLowerCase(),
      );
    }

    // Filter by player name (case-insensitive search)
    const trimmedPlayerFilter = playerNameFilter.trim().toLowerCase();
    if (trimmedPlayerFilter) {
      filtered = filtered.filter((item) =>
        item.player_name.toLowerCase().includes(trimmedPlayerFilter),
      );
    }

    // Filter by selected team
    if (selectedTeam) {
      const playersInSelectedTeam = teamList
        .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
        .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

      filtered = filtered.filter((item) =>
        playersInSelectedTeam.some((player) =>
          item.player_name.toLowerCase().includes(player),
        ),
      );
    }

    setFilteredData(filtered);
  };

  // Toggle column visibility
  const toggleColumn = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column]: !visibleColumns[column],
    });
  };

  // Toggle between showing all players or filtered players
  const handleTogglePlayerView = () => {
    setShowFilteredPlayers(!showFilteredPlayers);
  };

  // Helper function to filter players based on the selected team
  const getFilteredPlayerList = (playerList) => {
    if (!selectedTeam) {
      return playerList;
    }

    const playersInSelectedTeam = teamList
      .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
      .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

    return playerList
      .split(', ')
      .filter((player) => playersInSelectedTeam.includes(player.toLowerCase()))
      .join(', ');
  };

  // Toggle row visibility (minimize/expand)
  const toggleRowVisibility = (index) => {
    const newMinimizedRows = new Set(minimizedRows);
    if (minimizedRows.has(index)) {
      newMinimizedRows.delete(index);
    } else {
      newMinimizedRows.add(index);
    }
    setMinimizedRows(newMinimizedRows);
  };

  // Reset minimized rows
  const resetRows = () => {
    setMinimizedRows(new Set());
  };

  if (loading) {
    return (
      <LoadingSpinner text="Loading NBA Player vs. Defense Average Report..." />
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-6">
        NBA Player Defensive Averages Report
      </h1>

      <p className="text-sm text-neutral-600 text-center mb-8 max-w-3xl mx-auto">
        This report displays defensive statistics for individual players against
        specific opposing teams. First 10 days of season will be statistics from
        previous year.
      </p>

      <div className="flex flex-wrap gap-4 items-end justify-start mb-6">
        <div className="flex flex-col">
          <label
            htmlFor="opposingTeamFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Opposing Team
          </label>
          <select
            id="opposingTeamFilter"
            value={selectedOpposingTeam}
            onChange={(e) => setSelectedOpposingTeam(e.target.value)}
            className="border rounded-md px-3 py-2 text-sm"
          >
            <option value="">Select a Team</option>
            {opposingTeamList.map((team, index) => (
              <option key={index} value={team}>
                {team}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="playerNameFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Player Name
          </label>
          <input
            type="text"
            id="playerNameFilter"
            value={playerNameFilter}
            onChange={(e) => setPlayerNameFilter(e.target.value)}
            placeholder="Enter player name"
            className="border rounded-md px-3 py-2 text-sm"
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="teamFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Offensive Team
          </label>
          <select
            id="teamFilter"
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
            className="border rounded-md px-3 py-2 text-sm"
          >
            <option value="">Select a Team</option>
            {[...new Set(teamList.map((player) => player.team_name))].map(
              (team, index) => (
                <option key={index} value={team}>
                  {team}
                </option>
              ),
            )}
          </select>
        </div>

        <button
          onClick={handleFilterChange}
          className="bg-[#484242] text-white rounded-md px-4 py-2 text-sm mt-1"
        >
          Search
        </button>
      </div>

      <div className="mb-6">
        <label className="inline-flex items-center gap-2 text-sm text-neutral-700">
          <input
            type="checkbox"
            checked={showFilteredPlayers}
            onChange={handleTogglePlayerView}
          />
          Show only players from the selected offensive team
        </label>
      </div>

      <details className="mb-6 border border-neutral-200 rounded-md p-4 bg-neutral-50">
        <summary className="cursor-pointer text-sm font-medium text-[#484242]">
          Toggle Visible Columns
        </summary>
        <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 text-sm text-neutral-700">
          {Object.keys(visibleColumns).map((col) => (
            <label key={col} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={visibleColumns[col]}
                onChange={() => toggleColumn(col)}
              />
              {col
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => str.toUpperCase())}
            </label>
          ))}
        </div>
      </details>

      <div className="mb-6">
        <button
          className="text-sm border border-neutral-300 px-4 py-2 rounded-md hover:bg-neutral-100"
          onClick={resetRows}
        >
          Reset Grid
        </button>
      </div>

      <div className="overflow-auto">
        <table className="min-w-full border text-sm text-left text-neutral-700">
          <thead className="bg-[#f5f5f5] text-xs uppercase text-neutral-600">
            <tr>
              {visibleColumns.opposingTeam && (
                <th className="px-4 py-2">Opposing Team</th>
              )}
              {visibleColumns.avgPointsAllowed && (
                <th className="px-4 py-2">Avg Points Allowed</th>
              )}
              {visibleColumns.avgAssistsAllowed && (
                <th className="px-4 py-2">Avg Assists Allowed</th>
              )}
              {visibleColumns.avgReboundsAllowed && (
                <th className="px-4 py-2">Avg Rebounds Allowed</th>
              )}
              {visibleColumns.avg3PMAllowed && (
                <th className="px-4 py-2">Avg 3PM Allowed</th>
              )}
              {visibleColumns.avgStealsAllowed && (
                <th className="px-4 py-2">Avg Steals Allowed</th>
              )}
              {visibleColumns.avgBlocksAllowed && (
                <th className="px-4 py-2">Avg Blocks Allowed</th>
              )}
              {visibleColumns.avgTurnoversAllowed && (
                <th className="px-4 py-2">Avg Turnovers Allowed</th>
              )}
              {visibleColumns.playerList && (
                <th className="px-4 py-2">Player List</th>
              )}
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((metric, index) => (
                <tr key={index} className="border-b hover:bg-neutral-50">
                  {minimizedRows.has(index) ? (
                    <td colSpan="12" className="text-center px-4 py-3">
                      <button
                        onClick={() => toggleRowVisibility(index)}
                        className="text-[#484242] text-lg font-bold"
                      >
                        +
                      </button>
                    </td>
                  ) : (
                    <>
                      {visibleColumns.opposingTeam && (
                        <td className="px-4 py-2">{metric.opposing_team}</td>
                      )}
                      {visibleColumns.avgPointsAllowed && (
                        <td className="px-4 py-2">{metric.avg_points}</td>
                      )}
                      {visibleColumns.avgAssistsAllowed && (
                        <td className="px-4 py-2">{metric.avg_assists}</td>
                      )}
                      {visibleColumns.avgReboundsAllowed && (
                        <td className="px-4 py-2">{metric.avg_rebounds}</td>
                      )}
                      {visibleColumns.avg3PMAllowed && (
                        <td className="px-4 py-2">{metric.avg_3pm}</td>
                      )}
                      {visibleColumns.avgStealsAllowed && (
                        <td className="px-4 py-2">{metric.avg_steals}</td>
                      )}
                      {visibleColumns.avgBlocksAllowed && (
                        <td className="px-4 py-2">{metric.avg_blocks}</td>
                      )}
                      {visibleColumns.avgTurnoversAllowed && (
                        <td className="px-4 py-2">{metric.avg_turnovers}</td>
                      )}
                      {visibleColumns.playerList && (
                        <td className="px-4 py-2 max-w-xs">
                          <div className="max-h-24 overflow-y-auto whitespace-pre-line hover:overflow-y-scroll pr-1 text-xs">
                            {showFilteredPlayers
                              ? getFilteredPlayerList(metric.player_name)
                              : metric.player_name}
                          </div>
                        </td>
                      )}
                      <td className="px-4 py-2">
                        <button
                          onClick={() => toggleRowVisibility(index)}
                          className="text-red-600 font-bold"
                        >
                          -
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center py-4 text-neutral-500">
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NBAPlayerDAvgReport;
