import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import authReducer from './slices/authSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import nflReducer from './slices/nflSlice';
import nbaReducer from './slices/nbaSlice';
//import wnbaReducer from './slices/wnbaSlice';
import mlbReducer from './slices/mlbSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    subscription: subscriptionReducer,
    nfl: nflReducer,
    nba: nbaReducer,
    //wnba: wnbaReducer,
    mlb: mlbReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
