import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner.js';

const NFLClusterReport = () => {
  const [clusterData, setClusterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [opposingDefenseList, setOpposingDefenseList] = useState([]);
  const [selectedOpposingDefense, setSelectedOpposingDefense] = useState('');
  const [playerNameFilter, setPlayerNameFilter] = useState('');
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [showFilteredPlayers, setShowFilteredPlayers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [visibleColumns, setVisibleColumns] = useState({
    opposingDefense: true,
    fppgCluster: true,
    positionName: true,
    avgFppg: true,
    avgRushingYardsAgainst: true,
    avgRushingTdsAgainst: true,
    avgReceptionsAllowed: true,
    avgReceivingYardsAgainst: true,
    avgReceivingTdsAgainst: true,
    playerList: true,
  });
  const [minimizedRows, setMinimizedRows] = useState(new Set());

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/cluster-report/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(response.data.nfl_cluster_data)) {
          setClusterData(response.data.nfl_cluster_data);
          setFilteredData(response.data.nfl_cluster_data);

          // Extract unique opposing defenses for the dropdown
          const uniqueOpposingDefenses = [
            ...new Set(
              response.data.nfl_cluster_data.map(
                (item) => item.opposing_defense,
              ),
            ),
          ];
          setOpposingDefenseList(uniqueOpposingDefenses);
        } else {
          setClusterData([]);
          setFilteredData([]);
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching cluster report data.');
        setLoading(false);
      }
    };

    const fetchTeamData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const teamResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/player-team-map/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (Array.isArray(teamResponse.data.nfl_player_teams)) {
          setTeamList(teamResponse.data.nfl_player_teams);
        } else {
          setTeamList([]);
        }
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchClusterData();
    fetchTeamData();
  }, []);

  // Handle filtering the data by opposing defense, player name, and selected team
  const handleFilterChange = () => {
    let filtered = clusterData;

    // Filter by selected opposing defense
    if (selectedOpposingDefense) {
      filtered = filtered.filter(
        (item) =>
          item.opposing_defense.toLowerCase() ===
          selectedOpposingDefense.toLowerCase(),
      );
    }

    // Filter by player name (case-insensitive search)
    const trimmedPlayerFilter = playerNameFilter.trim().toLowerCase();
    if (trimmedPlayerFilter) {
      filtered = filtered.filter((item) =>
        item.player_list.toLowerCase().includes(trimmedPlayerFilter),
      );
    }

    // Filter by selected offensive team
    if (selectedTeam) {
      const playersInSelectedTeam = teamList
        .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
        .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

      filtered = filtered.filter((item) =>
        playersInSelectedTeam.some((player) =>
          item.player_list.toLowerCase().includes(player),
        ),
      );
    }

    setFilteredData(filtered);
  };

  // Helper function to get filtered players
  const getFilteredPlayerList = (playerList) => {
    if (!selectedTeam) {
      return playerList;
    }

    const playersInSelectedTeam = teamList
      .filter((teamPlayer) => teamPlayer.team_name === selectedTeam)
      .map((teamPlayer) => teamPlayer.player_name.toLowerCase());

    return playerList
      .split(', ')
      .filter((player) => playersInSelectedTeam.includes(player.toLowerCase()))
      .join(', ');
  };

  // Toggle row visibility (minimize/expand)
  const toggleRowVisibility = (index) => {
    const newMinimizedRows = new Set(minimizedRows);
    if (minimizedRows.has(index)) {
      newMinimizedRows.delete(index);
    } else {
      newMinimizedRows.add(index);
    }
    setMinimizedRows(newMinimizedRows);
  };

  if (loading) {
    return <LoadingSpinner text="Loading NFL Cluster Report..." />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-6">
        NFL Cluster Report
      </h1>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 items-end justify-start mb-6">
        <div className="flex flex-col">
          <label
            htmlFor="opposingDefenseFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Opposing Defense
          </label>
          <select
            id="opposingDefenseFilter"
            value={selectedOpposingDefense}
            onChange={(e) => setSelectedOpposingDefense(e.target.value)}
            className="border rounded-md px-3 py-2 text-sm"
          >
            <option value="">Select a Defense</option>
            {opposingDefenseList.map((defense, index) => (
              <option key={index} value={defense}>
                {defense}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="playerNameFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Player Name
          </label>
          <input
            type="text"
            id="playerNameFilter"
            value={playerNameFilter}
            onChange={(e) => setPlayerNameFilter(e.target.value)}
            placeholder="Enter player name"
            className="border rounded-md px-3 py-2 text-sm"
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="teamFilter"
            className="text-sm font-medium text-neutral-700 mb-1"
          >
            Filter by Offensive Team
          </label>
          <select
            id="teamFilter"
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
            className="border rounded-md px-3 py-2 text-sm"
          >
            <option value="">Select a Team</option>
            {[...new Set(teamList.map((player) => player.team_name))].map(
              (team, index) => (
                <option key={index} value={team}>
                  {team}
                </option>
              ),
            )}
          </select>
        </div>

        <button
          onClick={handleFilterChange}
          className="bg-[#484242] text-white rounded-md px-4 py-2 text-sm mt-1"
        >
          Search
        </button>
      </div>

      {/* Player toggle */}
      <div className="mb-6">
        <label className="inline-flex items-center gap-2 text-sm text-neutral-700">
          <input
            type="checkbox"
            checked={showFilteredPlayers}
            onChange={() => setShowFilteredPlayers(!showFilteredPlayers)}
          />
          Show only players from the selected offensive team
        </label>
      </div>

      {/* Table */}
      <div className="overflow-auto">
        <table className="min-w-full border text-sm text-left text-neutral-700">
          <thead className="bg-[#f5f5f5] text-xs uppercase text-neutral-600">
            <tr>
              {visibleColumns.opposingDefense && (
                <th className="px-4 py-2">Opposing Defense</th>
              )}
              {visibleColumns.fppgCluster && (
                <th className="px-4 py-2">FPPG Cluster</th>
              )}
              {visibleColumns.positionName && (
                <th className="px-4 py-2">Position</th>
              )}
              {visibleColumns.avgFppg && (
                <th className="px-4 py-2">Avg FPPG</th>
              )}
              {visibleColumns.avgRushingYardsAgainst && (
                <th className="px-4 py-2">Avg Rushing Yards</th>
              )}
              {visibleColumns.avgRushingTdsAgainst && (
                <th className="px-4 py-2">Avg Rushing TDs</th>
              )}
              {visibleColumns.avgReceptionsAllowed && (
                <th className="px-4 py-2">Avg Receptions</th>
              )}
              {visibleColumns.avgReceivingYardsAgainst && (
                <th className="px-4 py-2">Avg Receiving Yards</th>
              )}
              {visibleColumns.avgReceivingTdsAgainst && (
                <th className="px-4 py-2">Avg Receiving TDs</th>
              )}
              {visibleColumns.playerList && (
                <th className="px-4 py-2">Cluster Players</th>
              )}
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((metric, index) => (
                <tr key={index} className="border-b hover:bg-neutral-50">
                  {minimizedRows.has(index) ? (
                    <td colSpan="12" className="text-center px-4 py-3">
                      <button
                        onClick={() => toggleRowVisibility(index)}
                        className="text-[#484242] text-lg font-bold"
                      >
                        +
                      </button>
                    </td>
                  ) : (
                    <>
                      {visibleColumns.opposingDefense && (
                        <td className="px-4 py-2">{metric.opposing_defense}</td>
                      )}
                      {visibleColumns.fppgCluster && (
                        <td className="px-4 py-2">{metric.fppg_cluster}</td>
                      )}
                      {visibleColumns.positionName && (
                        <td className="px-4 py-2">{metric.position_name}</td>
                      )}
                      {visibleColumns.avgFppg && (
                        <td className="px-4 py-2">{metric.avg_fppg}</td>
                      )}
                      {visibleColumns.avgRushingYardsAgainst && (
                        <td className="px-4 py-2">
                          {metric.avg_rushing_yards_against}
                        </td>
                      )}
                      {visibleColumns.avgRushingTdsAgainst && (
                        <td className="px-4 py-2">
                          {metric.avg_rushing_tds_against}
                        </td>
                      )}
                      {visibleColumns.avgReceptionsAllowed && (
                        <td className="px-4 py-2">
                          {metric.avg_receptions_allowed}
                        </td>
                      )}
                      {visibleColumns.avgReceivingYardsAgainst && (
                        <td className="px-4 py-2">
                          {metric.avg_receiving_yards_against}
                        </td>
                      )}
                      {visibleColumns.avgReceivingTdsAgainst && (
                        <td className="px-4 py-2">
                          {metric.avg_receiving_tds_against}
                        </td>
                      )}
                      {visibleColumns.playerList && (
                        <td className="px-4 py-2">
                          {showFilteredPlayers
                            ? getFilteredPlayerList(metric.player_list)
                            : metric.player_list}
                        </td>
                      )}
                      <td className="px-4 py-2">
                        <button
                          onClick={() => toggleRowVisibility(index)}
                          className="text-red-600 font-bold"
                        >
                          -
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center py-4 text-neutral-500">
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NFLClusterReport;
