import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../../styles/data/GameDetails.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const NFLGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [error, setError] = useState('');
  const [isCheatSheetView, setIsCheatSheetView] = useState(false);
  const navigate = useNavigate();

  const handleError = useCallback((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        setError('You are not subscribed to this sport.');
      } else if (error.response.status === 404) {
        setError('Sport not found.');
      } else if (error.response.data && error.response.data.code === 'token_not_valid') {
        navigate('/login');
      } else {
        setError(`An unexpected error occurred: ${error.response.statusText}`);
      }
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  }, [navigate]);

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/2/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (permissionResponse.status === 200) {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setGameData(response.data);

          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, handleError]);

  const toggleExpansion = (teamName) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [teamName]: !prevExpandedSections[teamName],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!gameData) {
    return <div>Loading...</div>;
  }

  const { games, game_summary, weather_data, qb_predictions, recent_qb_performances, rb_predictions, wr_predictions, te_predictions, recent_positional_performances, kicker_predictions, recent_kicker_performances, player_odds, odd_comparison } = gameData;

  const selectedGame = games.find((game) => game.game_id.toString() === game_id);
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team, image: selectedGame.away_team_image_binary };
  const homeTeam = { name: selectedGame.home_team, image: selectedGame.home_team_image_binary };
  const awayTeamMetrics = teamMetricsData.find((metrics) => metrics.team_name === awayTeam.name);
  const homeTeamMetrics = teamMetricsData.find((metrics) => metrics.team_name === homeTeam.name);

  const gameSummary = game_summary.find(summary => summary.game_id.toString() === game_id);
  const awayTeamPoints = gameSummary ? parseFloat(gameSummary.winner === awayTeam.name ? gameSummary.winner_total : gameSummary.loser_total) : 0;
  const homeTeamPoints = gameSummary ? parseFloat(gameSummary.winner === homeTeam.name ? gameSummary.winner_total : gameSummary.loser_total) : 0;
  const totalPoints = gameSummary ? parseFloat(gameSummary.total_points) : 0;

  const weatherInfo = weather_data && weather_data.length > 0 ? weather_data[0] : null;

  const renderTeamMetricsDropdown = (teamMetrics, teamName) => {
    if (!teamMetrics) return null;

    return (
      <div className="metrics-dropdown">
        <button onClick={() => toggleExpansion(teamName)} className="metrics-toggle-button">
          {teamName} Metrics
        </button>
        {expandedSections[teamName] && (
          <div className="nested-dropdown">
            <div className="metric">Avg Passing Yards: {teamMetrics.average_passing_yards}</div>
            <div className="metric">Avg Rushing Yards: {teamMetrics.average_rushing_yards}</div>
            <div className="metric">Avg Receiving Yards: {teamMetrics.average_receiving_yards}</div>
            <div className="metric">Avg Points (Not including punt returns/2 PT Conversions): {teamMetrics.average_points}</div>
            <div className="metric">Avg Passing Yards Allowed: {teamMetrics.average_passing_yards_allowed}</div>
            <div className="metric">Avg Rushing Yards Allowed: {teamMetrics.average_rushing_yards_allowed}</div>
            <div className="metric">Avg Receiving Yards Allowed: {teamMetrics.average_receiving_yards_allowed}</div>
            <div className="metric">Avg Points Allowed (Not including punt returns/2 PT Conversions): {teamMetrics.average_points_allowed}</div>
          </div>
        )}
      </div>
    );
  };

  const mapQBData = (players, performances, player_odds, gameId, teamName) => {
    const qbMetrics = new Set([
      "Player pass completions",
      "Player pass attempts",
      "Player pass yds",
      "Player pass tds",
      "Player pass interceptions",
      "Player rush yds"
    ]);

    const qbPlayerOdds = player_odds.filter((odd) => qbMetrics.has(odd.metric));

    return players
      .filter((player) => player.game_id.toString() === gameId && player.player_team === teamName)
      .sort((a, b) => b.predicted_dfs_points - a.predicted_dfs_points)
      .map((player) => ({
        name: player.player_name,
        player_id: player.player_id,
        predictions: [
          { metric: 'Passing Completions', value: player.predicted_passing_completions },
          { metric: 'Passing Attempts', value: player.predicted_passing_attempts },
          { metric: 'Passing Yards', value: player.predicted_passing_yards },
          { metric: 'Passing Touchdowns', value: player.predicted_passing_tds },
          { metric: 'Rushing Yards', value: player.predicted_rushing_yards },
          { metric: 'Rushing Carries', value: player.predicted_rushing_carries },
          { metric: 'Rushing TDs', value: player.predicted_rushing_tds },
          { metric: 'Interceptions', value: player.predicted_passing_ints },
        ],
        recentPerformances: performances
          .filter((performance) => performance.player_id === player.player_id)
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            passingCompletions: performance.passing_completions,
            passingAttempts: performance.passing_attempts,
            passingYards: performance.passing_yards,
            passingTouchdowns: performance.passing_tds,
            rushingYards: performance.rushing_yards,
            rushingCarries: performance.rushing_carries,
            interceptions: performance.passing_ints,
          })),
          odds: qbPlayerOdds.filter((odd) => String(odd.player_id) === String(player.player_id)),
      }));
  };

  const mapPositionData = (players, performances, player_odds, gameId, teamName) => {
    const positionMetrics = new Set([
      "Player rush yds",
      "Player reception yds",
      "Player receptions",
      "Player rush attempts",
      "Player reception longest",
      "Player rush longest"
    ]);

    const positionalPlayerOdds = player_odds.filter((odd) => positionMetrics.has(odd.metric));

    return players
      .filter((player) => player.game_id.toString() === gameId && player.player_team === teamName)
      .sort((a, b) => b.predicted_dfs_points - a.predicted_dfs_points)
      .map((player) => ({
        name: player.player_name,
        player_id: player.player_id,
        predictions: [
          { metric: 'Receptions', value: player.predicted_receiving_receptions },
          { metric: 'Rushing Yards', value: player.predicted_rushing_yards },
          { metric: 'Receiving Yards', value: player.predicted_receiving_yards },
          { metric: 'Touchdowns', value: player.predicted_rushing_tds || player.predicted_receiving_tds },
        ],
        recentPerformances: performances
          .filter((performance) => performance.player_id === player.player_id)
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            rushingYards: performance.rushing_yards,
            rushingCarries: performance.rushing_carries,
            receivingYards: performance.receiving_yards,
            receptions: performance.receiving_receptions,
            touchdowns: performance.rushing_tds + performance.receiving_tds,
          })),
        odds: positionalPlayerOdds.filter((odd) => String(odd.player_id) === String(player.player_id)),
      }));
  };

  const mapKickerData = (players, performances, player_odds, teamName) => {
    const kickerMetrics = new Set([
      "Player field goals",
      "Player kicking points",
    ]);

    const kickerPlayerOdds = player_odds.filter((odd) => kickerMetrics.has(odd.metric));

    return players
      .filter((player) => player.player_team === teamName)
      .sort((a, b) => b.predicted_dfs_points - a.predicted_dfs_points)
      .map((player) => ({
        name: player.player_name,
        player_id: player.player_id,
        predictions: [
          {
            metric: 'Field Goals Made',
            value: (
              (parseFloat(player.predicted_fg_1_19_made) || 0) +
              (parseFloat(player.predicted_fg_20_29_made) || 0) +
              (parseFloat(player.predicted_fg_30_39_made) || 0) +
              (parseFloat(player.predicted_fg_40_49_made) || 0) +
              (parseFloat(player.predicted_fg_50_plus_made) || 0)
            ).toFixed(2)
          },
        ],
        recentPerformances: performances
          .filter((performance) => performance.player_id === player.player_id)
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            fgMade: performance.fg_1_19_made + performance.fg_20_29_made + performance.fg_30_39_made + performance.fg_40_49_made + performance.fg_50_plus_made,
            xpMade: performance.xp_made,
          })),
        odds: kickerPlayerOdds.filter((odd) => String(odd.player_id) === String(player.player_id)),
      }));
  };

  const createQBChartData = (predictions, recentPerformances, playerOdds, metric) => {
    const metricKeyMap = {
      'Passing Completions': 'passingCompletions',
      'Passing Attempts': 'passingAttempts',
      'Passing Yards': 'passingYards',
      'Passing Touchdowns': 'passingTouchdowns',
      'Interceptions': 'interceptions',
      'Rushing Yards': 'rushingYards',
    };

    const vegasMetricKeyMap = {
      'Player pass completions': 'passingCompletions',
      'Player pass attempts': 'passingAttempts',
      'Player pass yds': 'passingYards',
      'Player pass tds': 'passingTouchdowns',
      'Player pass interceptions': 'interceptions',
      'Player rush yds': 'rushingYards',
    };

    const vegasLine = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKeyMap[metric]
    )?.over_under;

    return createChartData(predictions, recentPerformances, metric, metricKeyMap, vegasLine);
  };

  const createPositionalChartData = (predictions, recentPerformances, playerOdds, metric) => {
    const metricKeyMap = {
      'Rushing Yards': 'rushingYards',
      'Receiving Yards': 'receivingYards',
      'Receptions': 'receptions',
      'Touchdowns': 'touchdowns',
    };

    const vegasMetricKeyMap = {
      'Player rush yds': 'rushingYards',
      'Player reception yds': 'receivingYards',
      'Player receptions': 'receptions',
    };

    const vegasLine = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKeyMap[metric]
    )?.over_under;

    return createChartData(predictions, recentPerformances, metric, metricKeyMap, vegasLine);
  };

  const createKickerChartData = (predictions, recentPerformances, playerOdds, metric) => {
    const metricKeyMap = {
      'Field Goals Made': 'fgMade',
    };

    const vegasMetricKeyMap = {
      'Player field goals': 'fgMade',
    };

    const vegasLine = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKeyMap[metric]
    )?.over_under;

    return createChartData(predictions, recentPerformances, metric, metricKeyMap, vegasLine);
  };

  const createChartData = (predictions, recentPerformances, metric, metricKeyMap, vegasLine) => {
    const metricKey = metricKeyMap[metric];

    // Sort performances by date
    const sortedPerformances = recentPerformances.sort((a, b) => new Date(a.gameDate) - new Date(b.gameDate));
    const recentPerformanceValues = sortedPerformances.map(p => parseFloat(p[metricKey])).filter(v => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return { labels: [], datasets: [] };
    }

    const predictionValue = parseFloat(predictions.find((p) => p.metric === metric)?.value);

    let vegasLineValue = null;

    if (typeof vegasLine === 'string') {
      vegasLineValue = parseFloat(vegasLine.trim());
    } else if (typeof vegasLine === 'number') {
      vegasLineValue = vegasLine;
    } else {
      console.warn('Invalid Vegas Line detected for metric:', metric, 'Vegas Line:', vegasLine);
    }

    if (isNaN(vegasLineValue)) {
      vegasLineValue = null;
    }

    return {
      labels: sortedPerformances.map((p) => p.gameDate),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues,
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        vegasLineValue !== null ? {
          label: 'Vegas Line',
          data: Array(recentPerformanceValues.length).fill(vegasLineValue),
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        } : null,
      ].filter(dataset => dataset !== null),
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
      },
    },
  };

  const awayQBs = mapQBData(qb_predictions, recent_qb_performances, player_odds, game_id, awayTeam.name);
  const homeQBs = mapQBData(qb_predictions, recent_qb_performances, player_odds, game_id, homeTeam.name);

  const awayRBs = mapPositionData(rb_predictions, recent_positional_performances, player_odds, game_id, awayTeam.name);
  const homeRBs = mapPositionData(rb_predictions, recent_positional_performances, player_odds, game_id, homeTeam.name);

  const awayWRs = mapPositionData(wr_predictions, recent_positional_performances, player_odds, game_id, awayTeam.name);
  const homeWRs = mapPositionData(wr_predictions, recent_positional_performances, player_odds, game_id, homeTeam.name);

  const awayTEs = mapPositionData(te_predictions, recent_positional_performances, player_odds, game_id, awayTeam.name);
  const homeTEs = mapPositionData(te_predictions, recent_positional_performances, player_odds, game_id, homeTeam.name);

  const awayKickers = mapKickerData(kicker_predictions, recent_kicker_performances, player_odds, awayTeam.name);
  const homeKickers = mapKickerData(kicker_predictions, recent_kicker_performances, player_odds, homeTeam.name);

  const DefenseVsPositionTDCheatSheet = () => {
    const [tdData, setTdData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedTeams, setExpandedTeams] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/td-trends/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const filteredTDData = response.data.td_trends_by_defense_vs_positions.filter(td => td.game_id.toString() === game_id);
          setTdData(filteredTDData.length > 0 ? filteredTDData : []);
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
     // eslint-disable-next-line
    }, [game_id, handleError]);

    const toggleTeamExpansion = (team) => {
      setExpandedTeams((prev) => ({
        ...prev,
        [team]: !prev[team],
      }));
    };

    if (loading) {
      return <div>Loading...</div>;
    }

    if (tdData.length === 0) {
      return <div>No data available.</div>;
    }

    return (
      <div className="cheat-sheet">
        <h1>Defense vs Position TD Cheat Sheet</h1>
        <div className="cheat-sheet-grid">
          {tdData.map((td, index) => (
            <div key={index} className="cheat-sheet-item">
              <div className="team-header" onClick={() => toggleTeamExpansion(td.team_name)}>
                Team: {td.team_name} - Position: {td.position_name} | (View Metrics)
              </div>
              {expandedTeams[td.team_name] && (
                <div className="player-details">
                  <div className="metric">Defense: {td.team_name}</div>
                  <div className="metric">Opposing Position: {td.position_name}</div>

                  <br></br>
                  <div className="metric">Games Played: {td.games_played}</div>
                  <br />
                  {/* Show metrics based on position */}
                  {td.position_name === 'Quarterback' && (
                    <>
                      <div className="metric">Passing TDs Allowed: {td.passing_tds_allowed}</div>
                      <div className="metric">Games with 0 Passing TDs: {td.games_with_0_passing_tds}</div>
                      {td.qb_with_0_passing_tds && td.qb_with_0_passing_tds.trim() && (
                        <div className="metric">QBs with 0 Passing TDs: {td.qb_with_0_passing_tds}</div>
                      )}
                      <div className="metric">Games with 1 Passing TD: {td.games_with_1_passing_td}</div>
                      <div className="metric">Games with 2+ Passing TDs: {td.games_with_2plus_passing_tds}</div>
                      {td.qb_with_2_plus_passing_tds && td.qb_with_2_plus_passing_tds.trim() && (
                        <div className="metric">QBs with 2+ Passing TDs: {td.qb_with_2_plus_passing_tds}</div>
                      )}
                    </>
                  )}
                  {td.position_name === 'Running Back' && (
                    <>
                      <div className="metric">ATDs Allowed (Rushing + Receiving TDs): {td.atd_allowed}</div>
                      {td.rb_with_0_atd && td.rb_with_0_atd.trim() && (
                        <div className="metric">Notable RBs with 0 ATDs: {td.rb_with_0_atd}</div>
                      )}
                      <div className="metric">Games with 0 ATDs: {td.games_with_0_atd}</div>
                      <div className="metric">Games with 1 ATD: {td.games_with_1_atd}</div>
                      <div className="metric">Games with 2+ ATDs: {td.games_with_2plus_atd}</div>
                      {td.rb_with_2_plus_atd && td.rb_with_2_plus_atd.trim() && (
                        <div className="metric">Notable RBs with 2+ ATDs: {td.rb_with_2_plus_atd}</div>
                      )}
                    </>
                  )}
                  {td.position_name === 'Wide Receiver' && (
                    <>
                      <div className="metric">ATDs Allowed (Rushing + Receiving TDs): {td.atd_allowed}</div>
                      {td.wr_with_0_atd && td.wr_with_0_atd.trim() && (
                        <div className="metric">Notable WRs with 0 ATDs: {td.wr_with_0_atd}</div>
                      )}
                      <div className="metric">Games with 0 ATDs: {td.games_with_0_atd}</div>
                      <div className="metric">Games with 1 ATD: {td.games_with_1_atd}</div>
                      <div className="metric">Games with 2+ ATDs: {td.games_with_2plus_atd}</div>
                      {td.wr_with_2_plus_atd && td.wr_with_2_plus_atd.trim() && (
                        <div className="metric">Notable WRs with 2+ ATDs: {td.wr_with_2_plus_atd}</div>
                      )}
                    </>
                  )}
                  {td.position_name === 'Tight End' && (
                    <>
                      <div className="metric">ATDs Allowed (Rushing + Receiving TDs): {td.atd_allowed}</div>
                      {td.te_with_0_atd && td.te_with_0_atd.trim() && (
                        <div className="metric">Notable TEs with 0 ATDs: {td.te_with_0_atd}</div>
                      )}
                      <div className="metric">Games with 0 ATDs: {td.games_with_0_atd}</div>
                      <div className="metric">Games with 1 ATD: {td.games_with_1_atd}</div>
                      <div className="metric">Games with 2+ ATDs: {td.games_with_2plus_atd}</div>
                      {td.te_with_2_plus_atd && td.te_with_2_plus_atd.trim() && (
                        <div className="metric">Notable TEs with 2+ ATDs: {td.te_with_2_plus_atd}</div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const NFLTrendHistoryCheatSheet = () => {
    const [trendData, setTrendData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchTrends = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/prop-trends/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const filteredTrendData = response.data.filter(trend => trend.game_id.toString() === game_id);
          setTrendData(filteredTrendData);
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchTrends();
       // eslint-disable-next-line
    }, [game_id, handleError]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (trendData.length === 0) {
      return <div>No trend data available.</div>;
    }

    return (
      <div className="cheat-sheet">
        <h1>NFL Trend History Cheat Sheet</h1>
        <div className="cheat-sheet-grid">
          {trendData.map((trend, index) => (
            <div key={index} className="cheat-sheet-item">
              <div>Player: {trend.player_name}</div>
              <div>Metric: {trend.metric}</div>
              <div>O/U: {trend.over_under}</div>
              <div>Current Streak: {trend.current_streak} ({trend.streak_direction})</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const NFLReverseCorrelationsCheatSheet = () => {
    const [reverseCorrelations, setReverseCorrelations] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchReverseCorrelations = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/reverse-correlations/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          const filteredCorrelations = response.data.nfl_reverse_correlations.filter(
            correlation => correlation.game_id.toString() === game_id
          );
          setReverseCorrelations(filteredCorrelations);
        } catch (error) {
          console.error('Error fetching inverse correlations:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchReverseCorrelations();
      // eslint-disable-next-line
    }, [game_id]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (reverseCorrelations.length === 0) {
      return <div>No inverse correlations available for this game.</div>;
    }
  
    return (
      <div className="cheat-sheet">
        <h1>NFL Potential Inverse Correlations Cheat Sheet</h1>
        <div className="cheat-sheet-grid">
          {reverseCorrelations.map((correlation, index) => (
            <div key={index} className="cheat-sheet-item">
              <div>Player: {correlation.player_name}</div>
              <br></br>
              <div>Metric 1: O/U {correlation.odds1} {correlation.metric1}</div>
              <div>Metric 2: O/U {correlation.odds2} {correlation.metric2}</div>
              <div>Prediction 1: {correlation.predicted1} {correlation.metric1}</div>
              <div>Prediction 2: {correlation.predicted2} {correlation.metric2}</div>
              <br></br>
              <div>
                Inverse Correlation Bet: {correlation.predicted1 > correlation.odds1 ? 'Over' : 'Under'} {correlation.odds1} {correlation.metric1} & {correlation.predicted2 > correlation.odds2 ? 'Over' : 'Under'} {correlation.odds2} {correlation.metric2}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };  

  const PlayerCheatSheet = ({ awayTeamName, homeTeamName }) => {
    const navigate = useNavigate();
    const [playerData, setPlayerData] = useState({});
    const [error, setError] = useState('');
    const [expandedPositions, setExpandedPositions] = useState({});

    const handleError = useCallback((error) => {
        if (error.response) {
            if (error.response.status === 403) {
                setError('You are not subscribed to this sport.');
            } else if (error.response.status === 404) {
                setError('Sport not found.');
            } else if (error.response.data && error.response.data.code === 'token_not_valid') {
                navigate('/login');
            } else {
                setError(`An unexpected error occurred: ${error.response.statusText}`);
            }
        } else if (error.request) {
            setError('No response received from the server.');
        } else {
            setError(`An unexpected error occurred: ${error.message}`);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/cheat-sheets/`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                // Filter the player data based on the away and home team names
                const qbData = response.data.qb_cheat_sheet.filter(player => 
                    player.opposing_team === awayTeamName || player.opposing_team === homeTeamName
                );
                const rbData = response.data.rb_cheat_sheet.filter(player => 
                    player.opposing_team === awayTeamName || player.opposing_team === homeTeamName
                );
                const wrData = response.data.wr_cheat_sheet.filter(player => 
                    player.opposing_team === awayTeamName || player.opposing_team === homeTeamName
                );
                const teData = response.data.te_cheat_sheet.filter(player => 
                    player.opposing_team === awayTeamName || player.opposing_team === homeTeamName
                );

                setPlayerData({
                    qb: qbData,
                    rb: rbData,
                    wr: wrData,
                    te: teData,
                });
            } catch (error) {
                handleError(error);
            }
        };

        fetchData();
    }, [awayTeamName, homeTeamName, handleError]);

    const togglePositionExpansion = (position) => {
        setExpandedPositions((prev) => ({
            ...prev,
            [position]: !prev[position],
        }));
    };

    if (error) {
        return <div>{error}</div>;
    }

    if (!Object.keys(playerData).length) {
        return <div>Loading...</div>;
    }

    return (
        <div className="cheat-sheet">
            <h1>Player Cheat Sheet</h1>
            {Object.keys(playerData).map(position => (
                <div key={position} className="position-section">
                    <div className="position-header" onClick={() => togglePositionExpansion(position)}>
                        {position.toUpperCase()} (View Players)
                    </div>
                    {expandedPositions[position] && (
                        <div className="player-list">
                            {playerData[position].map(player => (
                                <div key={player.player_name} className="player-row">
                                    <div className="player-name">{player.player_name}</div>
                                    <div>Opposing Team: {player.opposing_team}</div>
                                    <br></br>
                                    {position === 'qb' && (
                                        <>
                                            <div className="metric">QB Predicted Completions: {player.predicted_passing_completions}</div>
                                            <div className="metric">QB Predicted Attempts: {player.predicted_passing_attempts}</div>
                                            <div className="metric">QB Predicted Passing Yards: {player.predicted_passing_yards}</div>
                                            <div className="metric">QB Predicted TDs: {player.predicted_passing_tds}</div>
                                            <br></br>
                                            <div className="metric">Avg Passing Yards Allowed to QB by {player.opposing_team}: {player.average_passing_yards_allowed}</div>
                                            <div className="metric">Avg Points Allowed to Opposing Offense by {player.opposing_team}: {player.average_points_allowed}</div>
                                            <br></br>
                                            <div className="metric">Avg Points Scored by {player.player_name} Team: {player.predicted_qb_team_average_points_scored}</div>
                                        </>
                                    )}
                                    {position === 'rb' && (
                                        <>
                                            <div className="metric">RB Predicted Rushing Attempts: {player.predicted_rushing_carries}</div>
                                            <div className="metric">RB Predicted Rushing Yards: {player.predicted_rushing_yards}</div>
                                            <div className="metric">RB Predicted Rushing TDs: {player.predicted_rushing_tds}</div>
                                            <div className="metric">RB Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                            <br></br>
                                            <div className="metric">Avg Rushing Yards Allowed to RB by {player.opposing_team}: {player.average_rushing_yards_allowed}</div>
                                            <div className="metric">Avg Rushing Yards Allowed Rank to RB by {player.opposing_team}: {player.average_rushing_yards_allowed_rank}</div>
                                        </>
                                    )}
                                    {position === 'wr' && (
                                        <>
                                            <div className="metric">WR Predicted Targets: {player.predicted_receiving_targets}</div>
                                            <div className="metric">WR Predicted Receptions: {player.predicted_receiving_receptions}</div>
                                            <div className="metric">WR Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                            <br></br>
                                            <div className="metric">Avg Receiving Yards Allowed to WR by {player.opposing_team}: {player.average_receiving_yards_allowed}</div>
                                            <div className="metric">Avg Receiving Yards Allowed Rank to WR by {player.opposing_team}: {player.average_receiving_yards_allowed_rank}</div>
                                        </>
                                    )}
                                    {position === 'te' && (
                                        <>
                                            <div className="metric">TE Predicted Targets: {player.predicted_receiving_targets}</div>
                                            <div className="metric">TE Predicted Receptions: {player.predicted_receiving_receptions}</div>
                                            <div className="metric">TE Predicted Receiving Yards: {player.predicted_receiving_yards}</div>
                                            <br></br>
                                            <div className="metric">Avg Receiving Yards Allowed to TE by {player.opposing_team}: {player.average_receiving_yards_allowed}</div>
                                            <div className="metric">Avg Receiving Yards Allowed Rank to TE by {player.opposing_team}: {player.average_receiving_yards_allowed_rank}</div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

return (
  <div className="game-details">
    <div className="header">
      <div>
        <button id="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <button onClick={() => setIsCheatSheetView(!isCheatSheetView)}>
        {isCheatSheetView ? 'Hide Cheat Sheets' : 'Show Cheat Sheets'}
      </button>
    </div>

    {isCheatSheetView ? (
      <div className="cheat-sheets">
        <DefenseVsPositionTDCheatSheet />
        <NFLTrendHistoryCheatSheet />
        <NFLReverseCorrelationsCheatSheet />
        <PlayerCheatSheet awayTeamName={awayTeam.name} homeTeamName={homeTeam.name} />
      </div>
    ) : (
      <div id="game-details-content">
        <div className="team-logos">
          <img src={awayTeam.image ? `data:image/png;base64,${awayTeam.image}` : ''} alt={`${awayTeam.name} logo`} />
          <img src={homeTeam.image ? `data:image/png;base64,${homeTeam.image}` : ''} alt={`${homeTeam.name} logo`} />
        </div>
        <div className="game-display-ribbon">{selectedGame.game_display_name}</div>

        <div className="row centered-content">
          <div className="card">
            <h2>Game Summary & Weather Data</h2>
            <p>{awayTeam.name} Projected Total Points: {awayTeamPoints}</p>
            <p>{homeTeam.name} Projected Total Points: {homeTeamPoints}</p>
            <p>Point Differential: {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)} in favor of {awayTeamPoints > homeTeamPoints ? awayTeam.name : homeTeam.name}</p>
            <p>Total Points: {totalPoints}</p>
            {weatherInfo && (
              <>
                <br />
                <p>Temperature: {parseFloat(weatherInfo.temperature).toFixed(1)}</p>
                <p>Condition: {weatherInfo.weather_conditions}</p>
                <p>Wind: {weatherInfo.wind}</p>
                <p>Precipitation: {weatherInfo.precipitation}</p>
              </>
            )}
          </div>
        </div>

        <div className="row centered-content">
          <div>
            {renderTeamMetricsDropdown(awayTeamMetrics, awayTeam.name)}
          </div>
          <div>
            {renderTeamMetricsDropdown(homeTeamMetrics, homeTeam.name)}
          </div>
        </div>

        {odd_comparison && odd_comparison.length > 0 && 
          odd_comparison[0].vegas_predicted_total !== null && 
          odd_comparison[0].vegas_predicted_winner_spread !== null && (
          <div className="additional-section">
            <h2>Odd Comparison</h2>
            <p>Vegas Predicted Winner: {odd_comparison[0].vegas_predicted_winner}</p>
            <p>Vegas Predicted Total: {odd_comparison[0].vegas_predicted_total}</p>
            <p>Vegas Predicted Winner Spread: {odd_comparison[0].vegas_predicted_winner_spread}</p>
          </div>
        )}

        {/* Quarterbacks */}
        {awayQBs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{awayTeam.name} Quarterbacks</h2>
            <div className="team-details">
              {awayQBs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createQBChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.passingYards} Passing Yards, {performance.passingTouchdowns} TDs, {performance.interceptions} INTs
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Running Backs */}
        {awayRBs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{awayTeam.name} Running Backs</h2>
            <div className="team-details">
              {awayRBs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.rushingYards} Rushing Yards, {performance.touchdowns} TDs
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Wide Receivers */}
        {awayWRs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{awayTeam.name} Wide Receivers</h2>
            <div className="team-details">
              {awayWRs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.receivingYards} Receiving Yards, {performance.touchdowns} TD(s)
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Tight Ends */}
        {awayTEs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{awayTeam.name} Tight Ends</h2>
            <div className="team-details">
              {awayTEs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.receivingYards} Receiving Yards, {performance.touchdowns} TD(s)
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Kickers */}
        {awayKickers.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{awayTeam.name} Kickers</h2>
            <div className="team-details">
              {awayKickers.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createKickerChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.fgMade} FG Made
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Repeat similarly for home team quarterbacks, RBs, WRs, TEs, and Kickers */}
        {homeQBs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{homeTeam.name} Quarterbacks</h2>
            <div className="team-details">
              {homeQBs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createQBChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.passingYards} Passing Yards, {performance.passingTouchdowns} TDs, {performance.interceptions} INTs
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Home Running Backs */}
        {homeRBs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{homeTeam.name} Running Backs</h2>
            <div className="team-details">
              {homeRBs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.rushingYards} Rushing Yards, {performance.touchdowns} TDs
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Home Wide Receivers */}
        {homeWRs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{homeTeam.name} Wide Receivers</h2>
            <div className="team-details">
              {homeWRs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.receivingYards} Receiving Yards, {performance.touchdowns} TDs
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Home Tight Ends */}
        {homeTEs.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{homeTeam.name} Tight Ends</h2>
            <div className="team-details">
              {homeTEs.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createPositionalChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.receivingYards} Receiving Yards, {performance.touchdowns} TD(s)
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Home Kickers */}
        {homeKickers.length > 0 && (
          <div className="team-section">
            <h2 className="team-header">{homeTeam.name} Kickers</h2>
            <div className="team-details">
              {homeKickers.map(player => (
                <div key={player.name} className="player-details">
                  <h3>{player.name}</h3>
                  <div className="predictions">
                    {player.predictions.map((prediction, index) => (
                      <div key={index} className="prediction-card">
                        <h4>{prediction.metric}</h4>
                        <p>{prediction.value}</p>
                        <div className="visualizations">
                          <Line data={createKickerChartData(player.predictions, player.recentPerformances, player.odds, prediction.metric)} options={chartOptions} width={150} height={150} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="performances">
                    <h4>Recent Performances</h4>
                    {player.recentPerformances.map((performance, index) => (
                      <p key={index}>
                        {performance.gameDate}: {performance.fgMade} FG Made
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);
};

export default NFLGameDetails;
