import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthCheck = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        navigate('/'); // Redirect to login page if no token
        return;
      }

      try {
        // Make the authentication check request
        await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_SUBSCRIPTION_API_TYPE}check-auth/`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // No further action required on success
      } catch (error) {
        console.error('Authentication check failed:', error);

        if (error.response?.status === 401) {
          // Token is invalid or expired; redirect to login
          navigate('/');
        } else if (error.response?.status === 403) {
          // User's subscription has expired; redirect to dashboard for renewal options
          navigate('/dashboard');
        } else {
          // For other errors, redirect to a generic error page
          navigate('/error');
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  return <>{children}</>;
};

export default AuthCheck;
