import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import LoadingSpinner from '../common/LoadingSpinner';
import Button from '../common/Button';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const domeTeams = new Set([
  'Toronto',
  'Milwaukee',
  'Miami',
  'Houston',
  'Texas',
  'Arizona',
]);

const MLBGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [error, setError] = useState('');
  const [expandedSections, setExpandedSections] = useState({});
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/1/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (permissionResponse.status === 200) {
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setGameData(response.data);

          // Fetch the team metrics data
          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}mlb/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, navigate, handleError]);

  const toggleExpansion = (teamName, period) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [`${teamName}-${period}`]: !prevExpandedSections[`${teamName}-${period}`],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!gameData) {
    return <LoadingSpinner text="Loading game data..." />;
  }

  const calculatePredictedStrikeouts = (strikeoutRate, inningsPitched) => {
    const predictedStrikeoutsPer9Innings = strikeoutRate * 27;
    return (predictedStrikeoutsPer9Innings / 9) * inningsPitched;
  };

  const calculatePredictedHits = (battingAverage, inningsPitched) => {
    const predictedHitsPer9Innings = battingAverage * 27;
    return (predictedHitsPer9Innings / 9) * inningsPitched;
  };

  const {
    games,
    game_summary,
    batter_predictions,
    pitcher_predictions,
    recent_batter_performances,
    recent_pitcher_performances,
    weather_data,
    odd_comparison,
    player_odds,
  } = gameData;

  const selectedGame = games.find(
    (game) => game.game_id.toString() === game_id,
  );
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team };
  const homeTeam = { name: selectedGame.home_team };

  const gameSummary = game_summary.find(
    (summary) => summary.game_id.toString() === game_id,
  );
  const awayTeamPoints = gameSummary
    ? parseFloat(
        gameSummary.winner === awayTeam.name
          ? gameSummary.winner_total
          : gameSummary.loser_total,
      )
    : 0;
  const homeTeamPoints = gameSummary
    ? parseFloat(
        gameSummary.winner === homeTeam.name
          ? gameSummary.winner_total
          : gameSummary.loser_total,
      )
    : 0;
  const totalPoints = gameSummary ? parseFloat(gameSummary.total_runs) : 0;

  const weatherInfo =
    weather_data && weather_data.length > 0 ? weather_data[0] : null;

  const mapPitcherData = (players, performances, teamName, gameId) => {
    return players
      .filter(
        (player) =>
          player.game_id.toString() === gameId &&
          player.player_team === teamName,
      )
      .map((player) => ({
        name: player.player_name,
        opposing_batting_average_against_pitcher:
          player.opposing_batting_average_against_pitcher,
        opposing_strikeout_rate_against_pitcher:
          player.opposing_strikeout_rate_against_pitcher,
        opposing_team_season_batting_average:
          player.opposing_team_season_batting_average,
        opposing_team_strikeout_rate: player.opposing_team_strikeout_rate,
        opposing_team_average_runs_per_game_over_5_innings:
          player.opposing_team_average_runs_per_game_over_5_innings,
        opposing_team_average_runs_per_game_over_6_innings:
          player.opposing_team_average_runs_per_game_over_6_innings,
        opposing_team_average_runs_per_game_over_9_innings:
          player.opposing_team_average_runs_per_game_over_9_innings,
        predictions: [
          {
            metric: 'Innings Pitched',
            value: player.predicted_innings_pitched,
          },
          { metric: 'Strikeouts', value: player.predicted_strikeouts_thrown },
          { metric: 'Earned Runs', value: player.predicted_earned_runs },
          { metric: 'Hits Allowed', value: player.predicted_hits_allowed },
          { metric: 'Walks Issued', value: player.predicted_walks_issued },
        ],
        predictedStrikeouts: calculatePredictedStrikeouts(
          player.opposing_strikeout_rate_against_pitcher,
          player.predicted_innings_pitched,
        ),
        predictedHits: calculatePredictedHits(
          player.opposing_batting_average_against_pitcher,
          player.predicted_innings_pitched,
        ),
        recentPerformances: performances
          .filter(
            (performance) =>
              performance.player === player.player_name &&
              performance.player_team === teamName,
          )
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            game_display_name: performance.game_display_name,
            inningsPitched: performance.innings_pitched,
            strikeoutsThrown: performance.strikeouts_thrown,
            earnedRuns: performance.earned_runs,
            hitsAllowed: performance.hits_allowed,
            walksIssued: performance.walks_issued,
          })),
        opposingTeamStrikeoutsPer9Innings:
          player.opposing_team_strikeout_rate * 27,
        opposingTeamStrikeoutsPer6Innings:
          player.opposing_team_strikeout_rate * 18,
        opposingTeamStrikeoutsPer5Innings:
          player.opposing_team_strikeout_rate * 15,
      }));
  };

  const mapBatterData = (players, performances, teamName) => {
    return players
      .filter((player) => player.player_team === teamName)
      .map((player) => ({
        name: player.player_name,
        ab_against_opposing_pitcher: player.ab_against_opposing_pitcher,
        hits_against_opposing_pitcher: player.hits_against_opposing_pitcher,
        batting_average_against_opposing_pitcher:
          player.batting_average_against_opposing_pitcher,
        predictions: [
          { metric: 'Hits', value: player.predicted_hits },
          { metric: 'Home Runs', value: player.predicted_home_runs },
          { metric: 'Walks', value: player.predicted_walks },
        ],
        recentPerformances: performances
          .filter(
            (performance) =>
              performance.player === player.player_name &&
              performance.player_team === teamName,
          )
          .sort((a, b) => new Date(b.game_date) - new Date(a.game_date))
          .map((performance) => ({
            gameDate: performance.game_date,
            game_display_name: performance.game_display_name,
            at_bats: performance.at_bats,
            hits: performance.hits,
            home_runs: performance.home_runs,
            walks: performance.walks,
            strikeouts: performance.strikeouts,
          })),
      }));
  };

  const mapPlayerOdds = (playerName, playerOdds) => {
    return playerOdds
      .filter((odd) => odd.player_name === playerName)
      .map((odd) => ({
        metric: odd.metric,
        overUnder: odd.over_under,
        bettingOver: parseFloat(odd.betting_100_on_over_would_return),
        bettingUnder: parseFloat(odd.betting_100_on_under_would_return),
      }));
  };

  const awayPitchers = mapPitcherData(
    pitcher_predictions,
    recent_pitcher_performances,
    selectedGame.away_team,
    game_id,
  );
  const homePitchers = mapPitcherData(
    pitcher_predictions,
    recent_pitcher_performances,
    selectedGame.home_team,
    game_id,
  );
  const awayBatters = mapBatterData(
    batter_predictions,
    recent_batter_performances,
    selectedGame.away_team,
  );
  const homeBatters = mapBatterData(
    batter_predictions,
    recent_batter_performances,
    selectedGame.home_team,
  );

  const vegasMetricKeyMap = {
    Hits: 'hits',
    'Home Runs': 'home_runs',
    'Innings Pitched': 'inningsPitched',
    Strikeouts: 'strikeoutsThrown',
    'Earned runs': 'earnedRuns',
    'Hits allowed': 'hitsAllowed',
    Walks: 'walksIssued',
    'Total bases': 'total_bases',
    Rbis: 'rbis',
    'Runs scored': 'runs_scored',
    Outs: 'inningsPitched',
  };

  const createPitcherChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      'Innings Pitched': 'inningsPitched',
      Strikeouts: 'strikeoutsThrown',
      'Earned Runs': 'earnedRuns',
      'Hits Allowed': 'hitsAllowed',
      'Walks Issued': 'walksIssued',
    };

    const metricKey = metricKeyMap[metric];

    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => parseFloat(p[metricKey]))
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return { labels: [], datasets: [] };
    }

    const predictionValue = parseFloat(
      predictions.find((p) => p.metric === metric).value,
    );
    let vegasLine = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKey,
    )?.overUnder;

    if (
      metric === 'Innings Pitched' &&
      playerOdds.some((odd) => odd.metric === 'Outs')
    ) {
      vegasLine = playerOdds.find((odd) => odd.metric === 'Outs').overUnder / 3;
    }

    const vegasLineValue = parseFloat(vegasLine);

    return {
      labels: sortedPerformances.reverse().map((p) => p.gameDate),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues.reverse(),
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data: Array(recentPerformanceValues.length).fill(vegasLineValue),
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const createBatterChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      Hits: 'hits',
      'Home Runs': 'home_runs',
    };

    const metricKey = metricKeyMap[metric];

    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => {
        const value = parseFloat(p[metricKey]);
        return value;
      })
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return { labels: [], datasets: [] };
    }

    const prediction = predictions.find((p) => p.metric === metric);
    if (!prediction) {
      return { labels: [], datasets: [] };
    }
    const predictionValue = parseFloat(prediction.value);

    const vegasOdd = playerOdds.find(
      (odd) => vegasMetricKeyMap[odd.metric] === metricKey,
    );
    const vegasLine = vegasOdd ? vegasOdd.overUnder : null;
    const vegasLineValue = vegasLine ? parseFloat(vegasLine) : null;

    return {
      labels: sortedPerformances.reverse().map((p) => p.gameDate),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues.reverse(),
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 6.5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data:
            vegasLineValue !== null
              ? Array(recentPerformances.length).fill(vegasLineValue)
              : [],
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
      },
    },
  };

  const renderTeamMetricsDropdown = (teamName) => {
    const periodOrder = {
      last_7_days: 0,
      last_15_days: 1,
      last_30_days: 2,
      season: 3,
    };

    const periodLabels = {
      last_7_days: 'Last 7',
      last_15_days: 'Last 15',
      last_30_days: 'Last 30',
      season: 'Season',
    };

    const filteredMetrics = teamMetricsData
      .filter((metric) => metric.team_name === teamName)
      .sort((a, b) => periodOrder[a.period] - periodOrder[b.period]);

    return (
      <div className="flex flex-wrap gap-4">
        {filteredMetrics.map((metric, index) => {
          const key = `${metric.team_name}-${metric.period}`;
          const label = periodLabels[metric.period] || 'Season';

          return (
            <div key={index} className="w-full sm:w-auto">
              <Button
                onClick={() => toggleExpansion(metric.team_name, metric.period)}
                variant="outlinedPrimary"
                size="slimMedium"
                className="w-full"
              >
                {label}
              </Button>

              {expandedSections[key] && (
                <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mt-2 text-sm space-y-2 shadow-sm">
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Avg Runs
                    </span>
                    <span className="text-primary font-semibold">
                      {metric.average_runs}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Batting Avg
                    </span>
                    <span className="text-primary">
                      {metric.batting_average}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Strikeout Rate
                    </span>
                    <span className="text-primary">
                      {metric.strikeout_rate}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Walk Rate
                    </span>
                    <span className="text-primary">{metric.walk_rate}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-neutral-600 font-medium">
                      Hits/Inning
                    </span>
                    <span className="text-primary">
                      {metric.batter_hits_per_inning}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="game-details">
      <div className="mb-6">
        <h1 className="text-2xl font-heading text-primary text-center">
          MLB Game Details for {date}
        </h1>
      </div>

      <div className="flex justify-center items-center gap-6 mb-6 text-sm text-neutral-600">
        <div className="flex items-center gap-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: 'yellow' }}
          />
          Recent Performances
        </div>
        <div className="flex items-center gap-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: 'green' }}
          />
          Prediction Line
        </div>
        <div className="flex items-center gap-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: 'red' }}
          />
          Vegas Line
        </div>
      </div>

      <div id="game-details-content">
        <div className="bg-neutral text-white text-center py-4 rounded-xl text-xl font-heading mb-6">
          {selectedGame.game_display_name}
        </div>

        {/* Game Summary & Weather Data */}
        <div className="flex justify-center mb-10">
          <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:max-w-xl">
            <h2 className="text-xl font-heading text-primary mb-4">
              Game Summary & Weather Data
            </h2>
            <p className="text-base text-neutral mb-2">
              {awayTeam.name} Projected Total Runs:{' '}
              <span className="font-semibold">{awayTeamPoints}</span>
            </p>
            <p className="text-base text-neutral mb-2">
              {homeTeam.name} Projected Total Runs:{' '}
              <span className="font-semibold">{homeTeamPoints}</span>
            </p>
            <p className="text-base text-neutral mb-2">
              Point Differential:{' '}
              <span className="font-semibold">
                {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)}
              </span>{' '}
              in favor of{' '}
              <span className="font-semibold">
                {awayTeamPoints > homeTeamPoints
                  ? awayTeam.name
                  : homeTeam.name}
              </span>
            </p>
            <p className="text-base text-neutral mb-4">
              Total Points: <span className="font-semibold">{totalPoints}</span>
            </p>

            {weatherInfo && (
              <div className="text-sm text-neutral-700 space-y-1">
                <p>
                  Temperature: {parseFloat(weatherInfo.temperature).toFixed(1)}
                  °F
                </p>
                <p>Condition: {weatherInfo.weather_conditions}</p>
                <p>Wind: {weatherInfo.wind}</p>
                <p>Precipitation: {weatherInfo.precipitation}</p>
                {domeTeams.has(weatherInfo.game_location) && (
                  <p className="text-xs text-neutral-500 italic">
                    This game is played in a dome.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Team Metrics Dropdowns */}
        <div className="mb-12 flex flex-col lg:flex-row justify-center items-start gap-12">
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-heading text-primary mb-3">
              {awayTeam.name} Team Metrics
            </h2>
            {renderTeamMetricsDropdown(awayTeam.name)}
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-heading text-primary mb-3">
              {homeTeam.name} Team Metrics
            </h2>
            {renderTeamMetricsDropdown(homeTeam.name)}
          </div>
        </div>

        {odd_comparison && odd_comparison.length > 0 && (
          <div className="flex justify-center mb-12">
            <div className="bg-white rounded-xl shadow-lg p-6 w-full lg:max-w-xl">
              <h2 className="text-xl font-heading text-primary mb-4">
                Odds Comparison
              </h2>
              {odd_comparison.map((odds, index) => (
                <div
                  key={index}
                  className="space-y-2 text-sm text-neutral-700 border-b border-neutral-200 pb-4 mb-4 last:border-b-0 last:pb-0 last:mb-0"
                >
                  <p>
                    Vegas Predicted Total:{' '}
                    <span className="font-semibold">
                      {odds.vegas_predicted_total}
                    </span>
                  </p>
                  <p>
                    Vegas Predicted Winner:{' '}
                    <span className="font-semibold">
                      {odds?.vegas_predicted_winner}
                    </span>
                  </p>
                  <p>
                    Vegas Predicted Winner Spread:{' '}
                    <span className="font-semibold">
                      {odds?.vegas_predicted_winner_spread}
                    </span>
                  </p>
                  <p>
                    Pipeline Vegas Total Difference:{' '}
                    <span className="font-medium text-primary">
                      {odds?.pipeline_vegas_total_diff}
                    </span>
                  </p>
                  <p>
                    Pipeline Vegas Spread Difference:{' '}
                    <span className="font-medium text-primary">
                      {odds?.pipeline_vegas_spread_diff}
                    </span>
                  </p>
                  {odds.worthy_bet && (
                    <p className="text-success font-semibold">
                      Worthy Bet: {odds.worthy_bet}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mb-16">
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-6">
            {awayTeam.name} Pitchers
          </div>

          <div className="space-y-10">
            {awayPitchers.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Scouting Insights */}
                {(player.opposing_batting_average_against_pitcher ||
                  player.opposing_strikeout_rate_against_pitcher) && (
                  <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mb-6 text-sm space-y-2">
                    {player.opposing_batting_average_against_pitcher && (
                      <>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Opposing Batting Avg
                          </span>
                          <span className="text-primary font-semibold">
                            {player.opposing_batting_average_against_pitcher}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Predicted Hits from Avg
                          </span>
                          <span className="text-neutral-700">
                            {player.predictedHits.toFixed(2)}
                          </span>
                        </div>
                      </>
                    )}
                    {player.opposing_strikeout_rate_against_pitcher && (
                      <>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Opposing K Rate
                          </span>
                          <span className="text-primary font-semibold">
                            {player.opposing_strikeout_rate_against_pitcher}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Predicted Ks from Rate
                          </span>
                          <span className="text-neutral-700">
                            {player.predictedStrikeouts.toFixed(2)}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Predictions with Charts */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      <div className="h-40 w-full">
                        <Line
                          data={createPitcherChartData(
                            player.predictions,
                            player.recentPerformances,
                            mapPlayerOdds(player.name, player_odds),
                            prediction.metric,
                          )}
                          options={chartOptions}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                {/* Player Odds */}
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="mt-6 text-sm">
                    <h4 className="text-md font-semibold text-neutral-800 mb-2">
                      Player Odds
                    </h4>
                    <ul className="space-y-1">
                      {mapPlayerOdds(player.name, player_odds).map(
                        (odd, index) => (
                          <li
                            key={index}
                            className="flex justify-between text-neutral-600"
                          >
                            <span className="font-medium">{odd.metric}</span>
                            <span className="text-neutral-700 font-semibold">
                              {odd.overUnder}
                            </span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-16">
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-6">
            {homeTeam.name} Pitchers
          </div>

          <div className="space-y-10">
            {homePitchers.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Scouting Report Section */}
                {(player.opposing_batting_average_against_pitcher ||
                  player.opposing_strikeout_rate_against_pitcher) && (
                  <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mb-6 text-sm space-y-2">
                    {player.opposing_batting_average_against_pitcher && (
                      <>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Opposing Batting Avg
                          </span>
                          <span className="text-primary font-semibold">
                            {player.opposing_batting_average_against_pitcher}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Predicted Hits from Avg
                          </span>
                          <span className="text-neutral-700">
                            {player.predictedHits.toFixed(2)}
                          </span>
                        </div>
                      </>
                    )}
                    {player.opposing_strikeout_rate_against_pitcher && (
                      <>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Opposing K Rate
                          </span>
                          <span className="text-primary font-semibold">
                            {player.opposing_strikeout_rate_against_pitcher}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-neutral-600 font-medium">
                            Predicted Ks from Rate
                          </span>
                          <span className="text-neutral-700">
                            {player.predictedStrikeouts.toFixed(2)}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Prediction Chart Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      <div className="h-40 w-full">
                        <Line
                          data={createPitcherChartData(
                            player.predictions,
                            player.recentPerformances,
                            mapPlayerOdds(player.name, player_odds),
                            prediction.metric,
                          )}
                          options={chartOptions}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                {/* Player Odds */}
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="mt-6 text-sm">
                    <h4 className="text-md font-semibold text-neutral-800 mb-2">
                      Player Odds
                    </h4>
                    <ul className="space-y-1">
                      {mapPlayerOdds(player.name, player_odds).map(
                        (odd, index) => (
                          <li
                            key={index}
                            className="flex justify-between text-neutral-600"
                          >
                            <span className="font-medium">{odd.metric}</span>
                            <span className="text-neutral-700 font-semibold">
                              {odd.overUnder}
                            </span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-16">
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-6">
            {awayTeam.name} Batters
          </div>

          <div className="space-y-10">
            {awayBatters.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Matchup vs Pitcher */}
                {player.ab_against_opposing_pitcher > 0 && (
                  <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mb-6 text-sm space-y-2">
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        AB vs Pitcher
                      </span>
                      <span className="text-primary font-semibold">
                        {player.ab_against_opposing_pitcher}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        Hits vs Pitcher
                      </span>
                      <span className="text-primary">
                        {player.hits_against_opposing_pitcher}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        BA vs Pitcher
                      </span>
                      <span className="text-primary">
                        {player.batting_average_against_opposing_pitcher}
                      </span>
                    </div>
                  </div>
                )}

                {/* Prediction Cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      {['Hits', 'Home Runs', 'Walks'].includes(
                        prediction.metric,
                      ) && (
                        <div className="h-40 w-full">
                          <Line
                            data={createBatterChartData(
                              player.predictions,
                              player.recentPerformances,
                              mapPlayerOdds(player.name, player_odds),
                              prediction.metric,
                            )}
                            options={chartOptions}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Player Odds */}
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="mt-6 text-sm">
                    <h4 className="text-md font-semibold text-neutral-800 mb-2">
                      Player Odds
                    </h4>
                    <ul className="space-y-1">
                      {mapPlayerOdds(player.name, player_odds).map(
                        (odd, index) => (
                          <li
                            key={index}
                            className="flex justify-between text-neutral-600"
                          >
                            <span className="font-medium">{odd.metric}</span>
                            <span className="text-neutral-700 font-semibold">
                              {odd.overUnder}
                            </span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-16">
          <div className="bg-neutral text-white text-center py-3 rounded-xl text-xl font-heading mb-6">
            {homeTeam.name} Batters
          </div>

          <div className="space-y-10">
            {homeBatters.map((player) => (
              <div
                key={player.name}
                className="bg-white rounded-xl shadow-md p-6"
              >
                <h3 className="text-xl font-heading text-neutral-800 mb-4">
                  {player.name}
                </h3>

                {/* Matchup vs Pitcher */}
                {player.ab_against_opposing_pitcher > 0 && (
                  <div className="bg-primary-50 border border-primary-100 rounded-xl p-4 mb-6 text-sm space-y-2">
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        AB vs Pitcher
                      </span>
                      <span className="text-primary font-semibold">
                        {player.ab_against_opposing_pitcher}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        Hits vs Pitcher
                      </span>
                      <span className="text-primary">
                        {player.hits_against_opposing_pitcher}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-neutral-600 font-medium">
                        BA vs Pitcher
                      </span>
                      <span className="text-primary">
                        {player.batting_average_against_opposing_pitcher}
                      </span>
                    </div>
                  </div>
                )}

                {/* Prediction Cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {player.predictions.map((prediction, index) => (
                    <div
                      key={index}
                      className="bg-white p-4 rounded-lg shadow-md"
                    >
                      <h4 className="text-lg font-semibold text-primary mb-2">
                        {prediction.metric}
                      </h4>
                      <p className="text-sm text-neutral-700 mb-3">
                        Projection: {prediction.value}
                      </p>
                      {['Hits', 'Home Runs', 'Walks'].includes(
                        prediction.metric,
                      ) && (
                        <div className="h-40 w-full">
                          <Line
                            data={createBatterChartData(
                              player.predictions,
                              player.recentPerformances,
                              mapPlayerOdds(player.name, player_odds),
                              prediction.metric,
                            )}
                            options={chartOptions}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Player Odds */}
                {mapPlayerOdds(player.name, player_odds).length > 0 && (
                  <div className="mt-6 text-sm">
                    <h4 className="text-md font-semibold text-neutral-800 mb-2">
                      Player Odds
                    </h4>
                    <ul className="space-y-1">
                      {mapPlayerOdds(player.name, player_odds).map(
                        (odd, index) => (
                          <li
                            key={index}
                            className="flex justify-between text-neutral-600"
                          >
                            <span className="font-medium">{odd.metric}</span>
                            <span className="text-neutral-700 font-semibold">
                              {odd.overUnder}
                            </span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MLBGameDetails;
