import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FiStar } from 'react-icons/fi';
import { FiInfo } from 'react-icons/fi';

const NBAReverseCorrelations = () => {
  const [reverseCorrelations, setReverseCorrelations] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});
  const [clusterData, setClusterData] = useState([]);

  const [selectedCluster, setSelectedCluster] = useState(null);
  const [showClusterModal, setShowClusterModal] = useState(false);

  const handleError = useCallback((error) => {
    if (error.response) {
      setError(`An unexpected error occurred: ${error.response.statusText}`);
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);
  }, []);

  const isInClusterReport = (playerName, opposingTeam) => {
    return clusterData.some((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;

      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());

      return (
        players.includes(playerName.toLowerCase()) &&
        entry.opposing_defense.toLowerCase() === opposingTeam?.toLowerCase()
      );
    });
  };

  const handleIconClick = (playerName, opposingTeam) => {
    const matchingEntry = clusterData.find((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;
      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());

      return (
        players.includes(playerName.toLowerCase()) &&
        entry.opposing_defense.toLowerCase() === opposingTeam.toLowerCase()
      );
    });

    if (matchingEntry) {
      setSelectedCluster(matchingEntry);
      setShowClusterModal(true);
    }
  };

  useEffect(() => {
    const fetchClusterData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/cluster-report/`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setClusterData(response.data.nba_cluster_data || []);
      } catch (error) {
        console.error('Cluster fetch failed:', error);
      }
    };

    fetchClusterData();
  }, []);

  useEffect(() => {
    const fetchReverseCorrelationsAndGames = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const [gamesResponse, correlationsResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/current-games/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nba/reverse-correlations/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ]);

        setGames(gamesResponse.data);
        setReverseCorrelations(
          correlationsResponse.data.nba_reverse_correlations,
        );
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchReverseCorrelationsAndGames();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const formatReverseCorrelationBet = (
    metric1,
    metric2,
    odds1,
    odds2,
    predicted1,
    predicted2,
  ) => {
    const bet1 =
      predicted1 > odds1
        ? `Over ${odds1} ${metric1}`
        : `Under ${odds1} ${metric1}`;
    const bet2 =
      predicted2 > odds2
        ? `Over ${odds2} ${metric2}`
        : `Under ${odds2} ${metric2}`;
    return `${bet1} & ${bet2}`;
  };

  const isReverseClusterSupported = (
    playerName,
    opposingTeam,
    metric1,
    metric2,
    odds1,
    odds2,
  ) => {
    const m1 = metric1.toLowerCase();
    const m2 = metric2.toLowerCase();

    return clusterData.some((entry) => {
      if (!entry || !entry.player_list || !entry.opposing_defense) return false;

      const players = entry.player_list
        .toLowerCase()
        .split(', ')
        .map((p) => p.trim());

      const isPlayerInCluster = players.includes(playerName.toLowerCase());
      const isAgainstSameDefense =
        entry.opposing_defense.toLowerCase() === opposingTeam?.toLowerCase();

      if (!isPlayerInCluster || !isAgainstSameDefense) return false;

      // Cluster stats
      const avgPoints = parseFloat(entry.avg_points);
      const avgThrees = parseFloat(entry.avg_3pm);

      let clusterSupports = false;

      // Check if the correlation is between points and threes
      if (
        (m1 === 'threes' && m2 === 'points') ||
        (m1 === 'points' && m2 === 'threes')
      ) {
        const pointsThreshold = parseFloat(m1 === 'points' ? odds1 : odds2);
        const threesThreshold = parseFloat(m1 === 'threes' ? odds1 : odds2);

        // Logic: points > threshold, threes < threshold
        if (avgPoints > pointsThreshold && avgThrees < threesThreshold) {
          clusterSupports = true;
        }
      }

      return clusterSupports;
    });
  };

  if (loading)
    return <LoadingSpinner text="Loading NBA Inverse Correlation Report..." />;
  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      {/* Logo */}
      <div className="flex justify-center mb-4">
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="SportBetData Logo"
          className="h-36 object-contain"
        />
      </div>

      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        NBA Potential Inverse Correlations
      </h1>

      <div className="flex flex-wrap gap-6 justify-center items-center mb-6 text-sm text-gray-600">
        <div className="flex items-center gap-2">
          <FiStar className="text-yellow-500" />
          <span>
            Player’s inverse correlation is supported by cluster data and they
            are predicted for inverse!
          </span>
        </div>
        <div className="flex items-center gap-2">
          <FiInfo className="text-blue-500" />
          <span>
            Player’s inverse correlation is NOT supported by cluster data but
            they are predicted for inverse!
          </span>
        </div>
      </div>

      {games.length > 0 ? (
        games.map((game) => (
          <div
            key={game.game_id}
            className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
          >
            <div
              className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-medium text-base sm:text-lg">
                {game.game_display_name}
              </span>
              {expandedGames[game.game_id] ? (
                <FiChevronUp className="w-5 h-5 text-white" />
              ) : (
                <FiChevronDown className="w-5 h-5 text-white" />
              )}
            </div>

            {expandedGames[game.game_id] && (
              <div className="p-6 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {reverseCorrelations
                  .filter(
                    (correlation) =>
                      String(correlation.game_id) === String(game.game_id),
                  )
                  .map((correlation, index) => (
                    <div
                      key={index}
                      className="border border-neutral-200 rounded-lg p-4 bg-white hover:shadow-md transition"
                    >
                      <div className="font-semibold text-[#484242] text-base mb-2 flex items-center gap-2">
                        {correlation.player_name}
                        {isReverseClusterSupported(
                          correlation.player_name,
                          correlation.opposing_team,
                          correlation.metric1,
                          correlation.metric2,
                          correlation.odds1,
                          correlation.odds2,
                        ) ? (
                          <FiStar
                            className="text-yellow-500 cursor-pointer"
                            title="Cluster Supported Inverse"
                            onClick={() =>
                              handleIconClick(
                                correlation.player_name,
                                correlation.opposing_team,
                              )
                            }
                          />
                        ) : isInClusterReport(
                            correlation.player_name,
                            correlation.opposing_team,
                          ) ? (
                          <FiInfo
                            className="text-blue-500 cursor-pointer"
                            title="In Cluster Report (Click for details)"
                            onClick={() =>
                              handleIconClick(
                                correlation.player_name,
                                correlation.opposing_team,
                              )
                            }
                          />
                        ) : null}
                      </div>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric 1:</span> O/U{' '}
                        {correlation.odds1} {correlation.metric1}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric 2:</span> O/U{' '}
                        {correlation.odds2} {correlation.metric2}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Prediction 1:</span>{' '}
                        {correlation.predicted1} {correlation.metric1}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Prediction 2:</span>{' '}
                        {correlation.predicted2} {correlation.metric2}
                      </p>
                      <p className="text-sm text-neutral-800 font-semibold mt-2">
                        <span className="text-[#484242] font-bold">
                          Inverse Correlation Bet:
                        </span>{' '}
                        {formatReverseCorrelationBet(
                          correlation.metric1,
                          correlation.metric2,
                          correlation.odds1,
                          correlation.odds2,
                          correlation.predicted1,
                          correlation.predicted2,
                        )}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center text-neutral-500">No games available</div>
      )}

      {showClusterModal && selectedCluster && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
            <button
              className="absolute top-2 right-3 text-gray-500 hover:text-black text-xl"
              onClick={() => setShowClusterModal(false)}
            >
              &times;
            </button>
            <h2 className="text-lg font-bold mb-4 text-[#484242]">
              Cluster Stats vs {selectedCluster.opposing_defense}
            </h2>
            <div className="text-sm text-gray-700 space-y-2">
              <p>
                <strong>Players:</strong> {selectedCluster.player_list}
              </p>
              <p>
                <strong>Avg Points:</strong> {selectedCluster.avg_points}
              </p>
              <p>
                <strong>Avg Threes:</strong> {selectedCluster.avg_3pm}
              </p>
              <p>
                <strong>Avg Assists:</strong> {selectedCluster.avg_assists}
              </p>
              <p>
                <strong>Avg Rebounds:</strong> {selectedCluster.avg_rebounds}
              </p>
              <p>
                <strong>Avg Steals:</strong> {selectedCluster.avg_steals}
              </p>
              <p>
                <strong>Avg Blocks:</strong> {selectedCluster.avg_blocks}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NBAReverseCorrelations;
