import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubscribedSports,
  clearUserState,
} from '../../../redux/slices/subscriptionSlice';
import LoadingSpinner from '../../common/LoadingSpinner.js';
import Carousel from '../../common/Carousel.js';
import Subscribe from '../../payment/Subscribe.js';

const Dashboard = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [view, setView] = useState('sports');
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [reportImages, setReportImages] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Get subscribed sports and loading state from Redux
  const { subscribedSports, isLoading } = useSelector(
    (state) => state.subscription,
  );

  // Check if the user has an active subscription
  const hasActiveSubscription = subscribedSports.length > 0;

  // Define the desired sport order
  const sportOrder = ['NBA', 'WNBA', 'NFL', 'MLB'];

  const handleLogout = () => {
    dispatch(clearUserState());
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const closeSubscribeModal = () => {
    setShowSubscribeModal(false);
  };

  // Load images from assets dynamically
  useEffect(() => {
    const totalImages = 100;
    const tempImages = [];

    const checkImages = async () => {
      for (let i = 1; i <= totalImages; i++) {
        const imgPath = `/assets/reports/pic_${i}.jpg`;
        const imgExists = await imageExists(imgPath);
        if (imgExists) {
          tempImages.push(imgPath);
        } else {
          break;
        }
      }
      setReportImages(tempImages);
    };

    checkImages();
  }, []);

  const imageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    dispatch(fetchSubscribedSports());
  }, [dispatch]);

  const handleSlideClick = (index) => {
    if (view === 'sports') {
      if (index === activeSlide) {
        navigate(`/sport/${sortedSports[activeSlide].sport_id}`);
      } else if (index === (activeSlide + 1) % sortedSports.length) {
        setActiveSlide((prev) => prev % sortedSports.length);
      } else if (
        index ===
        (activeSlide - 1 + sortedSports.length) % sortedSports.length
      ) {
        setActiveSlide(
          (prev) => (prev + sortedSports.length) % sortedSports.length,
        );
      } else {
        setActiveSlide(index);
      }
    } else {
      if (index === activeSlide) {
        settingsSlides[index].action();
      } else if (index === (activeSlide + 1) % settingsSlides.length) {
        setActiveSlide((prev) => prev % settingsSlides.length);
      } else if (
        index ===
        (activeSlide - 1 + settingsSlides.length) % settingsSlides.length
      ) {
        setActiveSlide(
          (prev) => (prev + settingsSlides.length) % settingsSlides.length,
        );
      } else {
        setActiveSlide(index);
      }
    }
  };

  const settingsSlides = [
    {
      icon: '/assets/images/user-settings.png',
      title: 'User Settings',
      action: () => navigate('/user-settings'),
    },
    {
      icon: '/assets/images/faq.png',
      title: 'FAQ',
      action: () => navigate('/faq'),
    },
    {
      icon: '/assets/images/file-issue.png',
      title: 'File Issue',
      action: () => navigate('/file-issue'),
    },
    {
      icon: '/assets/images/logout.ico',
      title: 'Logout',
      action: handleLogout,
    },
  ];

  const subscriptionSlide = {
    header: 'Subscribe Now',
    content: (
      <div
        className="flex flex-col items-center justify-center cursor-pointer transition-transform duration-500 hover:scale-105"
        onClick={() => setShowSubscribeModal(true)}
      >
        <img
          src="/assets/images/subscribe.ico"
          alt="Subscribe"
          className="max-h-96 w-auto object-contain"
        />
        <p className="mt-0 text-2xl font-heading">
          Get full access to sports insights and detailed performance analytics.
        </p>
        <p className="text-primary-500">Click to Subscribe</p>
      </div>
    ),
  };

  const sportFeaturesMap = {
    NBA: [
      'Player Predictions',
      'Game Predictions',
      'Integrated Vegas Odds',
      'Prop Visualizations',
      'Prop History Trends',
      'Trend History Cheat Sheet',
      'Position Metrics Report',
      'PPG Cluster Report',
      'Potential Inverse Correlations',
      'Player Opposing Defense Report',
      //'Due to Break Streak',
    ],
    WNBA: [
      'Player Predictions',
      'Game Predictions',
      'Integrated Vegas Odds',
      'Prop Visualizations',
      'Prop History Trends',
      'Trend History Cheat Sheet',
      'Position Metrics Report',
      'PPG Cluster Report',
      'Potential Inverse Correlations',
      'Player Opposing Defense Report',
      //'Due to Break Streak',
    ],
    NFL: [
      'Player Predictions',
      'Game Predictions',
      'Integrated Vegas Odds',
      'Prop Visualizations',
      'Prop History Trends',
      'Trend History Cheat Sheet',
      'Potential Inverse Correlations',
      'Position vs. Defense Cheat Sheet',
      //'Due to Break Streak',
    ],
    MLB: [
      'Batter Predictions',
      'Pitcher Predictions',
      'Game Predictions',
      'Integrated Vegas Odds',
      'Prop Visualizations',
      'Home of HR Drought Data',
      'Trend History Cheat Sheet',
      'Potential Inverse Correlations',
      //'Due to Break Streak',
    ],
  };

  // Sort subscribed sports based on the predefined order
  const sortedSports = subscribedSports.slice().sort((a, b) => {
    return sportOrder.indexOf(a.sport) - sportOrder.indexOf(b.sport);
  });

  const currentSlides =
    view === 'sports'
      ? hasActiveSubscription
        ? sortedSports.map((sport, index) => ({
            header: `Subscribed Sport: ${sport.sport}`,
            content: (
              <div
                className="flex flex-col items-center justify-center cursor-pointer transition-transform duration-500 hover:scale-105"
                onClick={() => handleSlideClick(index)}
              >
                <img
                  src={`data:image/jpeg;base64,${sport.sport_image}`}
                  alt={sport.sport}
                  className="max-h-96 w-auto object-contain"
                />
                <p className="mt-0 text-2xl font-heading">{sport.sport}</p>
              </div>
            ),
            features: sportFeaturesMap[sport.sport] ?? [],
          }))
        : [subscriptionSlide]
      : settingsSlides.map((card, index) => ({
          header: 'Settings & Information',
          content: (
            <div
              key={index}
              className="flex flex-col items-center justify-center cursor-pointer transition-transform duration-500 hover:scale-105"
              onClick={() => handleSlideClick(index)}
            >
              <img
                src={card.icon}
                alt={card.title}
                className="max-h-96 w-auto object-contain"
              />
              <p className="mt-0 text-2xl font-heading">{card.title}</p>
            </div>
          ),
        }));

  if (isLoading) {
    return <LoadingSpinner text="Loading your dashboard..." />;
  }

  return (
    <div className="relative flex flex-col min-h-screen">
      {/* Logo */}
      <div
        className="flex justify-center mt-8 cursor-pointer"
        onClick={() => navigate('/')}
      >
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="Sport Bet Data Logo"
          className="w-40"
        />
      </div>

      {/* Toggle for Sports and Settings */}
      <div className="absolute top-8 right-8 flex items-center gap-2">
        <span
          className={`cursor-pointer ${
            view === 'sports' ? 'text-primary font-bold' : 'text-gray-500'
          }`}
          onClick={() => {
            setView('sports');
            setActiveSlide(0);
          }}
        >
          Sports
        </span>
        <span>|</span>
        <span
          className={`cursor-pointer ${
            view === 'settings' ? 'text-primary font-bold' : 'text-gray-500'
          }`}
          onClick={() => {
            setView('settings');
            setActiveSlide(0);
          }}
        >
          Settings
        </span>
      </div>

      {view === 'sports' ? (
        hasActiveSubscription ? (
          <Carousel
            header="Your Subscribed Sports"
            items={currentSlides}
            activeSlide={activeSlide}
            handlePrev={() =>
              setActiveSlide(
                (prev) =>
                  (prev - 1 + currentSlides.length) % currentSlides.length,
              )
            }
            handleNext={() =>
              setActiveSlide((prev) => (prev + 1) % currentSlides.length)
            }
            handleClick={(index) => setActiveSlide(index)}
            features={currentSlides[activeSlide]?.features || []}
          />
        ) : (
          <div className="flex flex-col items-center justify-center mt-12">
            <h2 className="text-2xl font-bold text-center text-primary-700">
              Subscribe to Access Exclusive Sports Insights!
            </h2>
            <p className="text-center text-neutral-600 mt-4">
              Unlock player predictions, game insights, and more with a
              subscription.
            </p>
            <button
              className="mt-6 bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-6 rounded transition-all"
              onClick={() => setShowSubscribeModal(true)}
            >
              Subscribe Now
            </button>

            {/* Display images to excite the user */}
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
              {reportImages.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image}
                    alt={`Report ${index + 1}`}
                    className="rounded-lg shadow-md hover:scale-105 transition-transform duration-300 cursor-pointer"
                    onClick={() => setModalImage(image)}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        // Always display settings for both subscribed and unsubscribed users
        <Carousel
          header="Settings & Information"
          items={currentSlides}
          activeSlide={activeSlide}
          handlePrev={() =>
            setActiveSlide(
              (prev) =>
                (prev - 1 + currentSlides.length) % currentSlides.length,
            )
          }
          handleNext={() =>
            setActiveSlide((prev) => (prev + 1) % currentSlides.length)
          }
          handleClick={(index) => setActiveSlide(index)}
        />
      )}

      {/* Subscription Modal */}
      {showSubscribeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <Subscribe onClose={closeSubscribeModal} />
        </div>
      )}

      {/* Modal for Viewing Images */}
      {modalImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black/70 z-50"
          onClick={() => setModalImage(null)}
        >
          <div className="relative max-w-4xl w-full p-4 sm:p-6">
            <img
              src={modalImage}
              alt="Modal View"
              className="rounded-lg shadow-lg w-full h-auto max-h-[90vh] object-contain"
            />
            <button
              onClick={() => setModalImage(null)}
              className="absolute top-4 right-4 text-white text-2xl font-bold hover:text-primary transition-colors"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
