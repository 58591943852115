import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMLBPitcherPropTrends } from '../../../redux/slices/mlbSlice';
import LoadingSpinner from '../../common/LoadingSpinner';

const MLBPitcherPropTrendHistoryCheatSheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Accessing Redux state
  const trendData = useSelector((state) => state.mlb.pitcherPropTrends);
  const loading = useSelector((state) => state.mlb.isLoading);
  const error = useSelector((state) => state.mlb.error);

  const [expandedPlayers, setExpandedPlayers] = useState({});

  useEffect(() => {
    dispatch(fetchMLBPitcherPropTrends());
  }, [dispatch]);

  const togglePlayerExpansion = (playerId) => {
    setExpandedPlayers((prev) => ({
      ...prev,
      [playerId]: !prev[playerId],
    }));
  };

  if (error) {
    return <div className="text-red-500 text-center mt-6">{error}</div>;
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <LoadingSpinner text="Report generating..." />;
      </div>
    );
  }

  const players = Array.from(
    new Set(trendData.map((trend) => trend.player_name)),
  );

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-neutral-800">
          Pitcher Prop Trend History Cheat Sheet
        </h1>
        <button
          className="bg-primary text-white px-4 py-2 rounded-lg shadow-md hover:bg-primary-700 transition"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <div className="space-y-4">
        {players.map((player) => (
          <div
            key={player}
            className="border border-neutral-200 rounded-lg shadow-sm p-4"
          >
            <div
              className="flex justify-between items-center cursor-pointer text-lg font-medium text-neutral-700 hover:text-primary"
              onClick={() => togglePlayerExpansion(player)}
            >
              {player} (View Prop Trends. Click to expand)
              <img
                src={
                  expandedPlayers[player]
                    ? '/assets/icons/misc/collapse_icon.png'
                    : '/assets/icons/misc/expand_icon.png'
                }
                alt={expandedPlayers[player] ? 'Collapse' : 'Expand'}
                className="w-6 h-6"
              />
            </div>
            {expandedPlayers[player] && (
              <div className="mt-4 bg-neutral-50 p-4 rounded-lg">
                {trendData
                  .filter((trend) => trend.player_name === player)
                  .map((trend) => (
                    <div
                      key={trend.metric}
                      className="border-b last:border-none pb-2 mb-2"
                    >
                      <div className="text-neutral-800 font-semibold">
                        {trend.metric}:{' '}
                        <span className="text-primary font-bold">
                          O/U {trend.over_under}
                        </span>
                      </div>
                      <div className="text-neutral-600">
                        Current Streak: {trend.current_streak} (
                        {trend.streak_direction})
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MLBPitcherPropTrendHistoryCheatSheet;
