import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import '../../../styles/data/CheatSheet.css';

const WNBATrendHistoryCheatSheet = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedGames, setExpandedGames] = useState({});

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You are not subscribed to this sport.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
      setLoading(false);
    },
    [navigate],
  );

  useEffect(() => {
    const fetchGamesAndTrends = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const gamesResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/current-games/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        const trendsResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/prop-trends/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setGames(gamesResponse.data);
        setTrendData(trendsResponse.data);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchGamesAndTrends();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({
      ...prev,
      [gameId]: !prev[gameId],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingSpinner text="Report generating..." />;
      </div>
    );
  }

  return (
    <div className="cheat-sheet">
      <div className="header">
        <h1>WNBA Prop Trend History Cheat Sheet</h1>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div className="game-list">
        {games.length > 0 ? (
          games.map((game) => (
            <div key={game.game_id} className="game-section">
              <div
                className="game-header"
                onClick={() => toggleGameExpansion(game.game_id)}
              >
                {game.game_display_name} (View Trends)
                <img
                  src={
                    expandedGames[game.game_id]
                      ? '/assets/icons/misc/collapse_icon.png'
                      : '/assets/icons/misc/expand_icon.png'
                  }
                  alt={expandedGames[game.game_id] ? 'Collapse' : 'Expand'}
                  className="expand-collapse-icon"
                />
              </div>
              {expandedGames[game.game_id] && (
                <div className="trend-list">
                  {trendData
                    .filter(
                      (trend) => String(trend.game_id) === String(game.game_id),
                    )
                    .sort((a, b) => a.player_name.localeCompare(b.player_name))
                    .map((trend) => (
                      <div
                        key={`${trend.player_name}-${trend.metric}`}
                        className="trend-row"
                      >
                        <div className="player-header">
                          <strong>{trend.player_name}</strong>
                        </div>
                        <div className="metric-row">
                          <div className="metric">
                            {trend.metric}: O/U {trend.over_under}
                          </div>
                          <div className="metric">
                            Current Streak: {trend.current_streak} (
                            {trend.streak_direction})
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <div>No games available</div>
        )}
      </div>
    </div>
  );
};

export default WNBATrendHistoryCheatSheet;
