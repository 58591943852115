/* 
########################################################################
# Imports
########################################################################
*/

// General Imports
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Wrappers
import StripeContext from './context/StripeContext';
import AuthCheck from './context/AuthCheck';

// Page Components
import Home from './components/home/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import PrivacyPolicy from './components/misc/PrivacyPolicy';
import TermsConditions from './components/misc/TermsConditions';
import ErrorPage from './components/misc/ErrorPage';
import ConfirmEmail from './components/auth/ConfirmEmail';
import Dashboard from './components/user/Dashboard';
import GenerateSlipForm from './components/user/GenerateSlipForm';
import Faq from './components/user/Faq';
import FileIssue from './components/user/FileIssue';
import Sport from './components/user/Sport';
import GameDetailsPage from './components/user/GameDetailsPage';
import UserSettings from './components/user/UserSettings';
import SampleDataPage from './components/user/SampleDataPage';

// Baseball Cheat Sheets
import GoodHistoryCheatSheet from './components/data/baseball/GoodHistoryCheatSheet';
import HRDroughtBatterGoodHistoryCheatSheet from './components/data/baseball/HRDroughtBatterGoodHistoryCheatSheet';
import HitDroughtBatterGoodHistoryCheatSheet from './components/data/baseball/HitDroughtBatterGoodHistoryCheatSheet';
import HotBatterCheatSheet from './components/data/baseball/HotBatterCheatSheet';
import HRDroughtCheatSheet from './components/data/baseball/HRDroughtCheatSheet';
import HitDroughtCheatSheet from './components/data/baseball/HitDroughtCheatSheet';
import HRDuos from './components/data/baseball/HRDuos';
import HRStats from './components/data/baseball/HRStats';
import PitcherCheatSheet from './components/data/baseball/PitcherCheatSheet';
import MLBPitcherPropTrendHistoryCheatSheet from './components/data/baseball/MLBPitcherPropTrendHistoryCheatSheet';

// NFL Cheat Sheets
import PlayerCheatSheet from './components/data/football/PlayerCheatSheet';
import NFLTrendHistoryCheatSheet from './components/data/football/NFLTrendHistoryCheatSheet';
import DefenseVsPositionTDCheatSheet from './components/data/football/DefenseVsPositionTDCheatSheet';
import NFLClusterReport from './components/data/football/NFLClusterReport';
import NFLReverseCorrelations from './components/data/football/NFLReverseCorrelations';

// WNBA Basketball Cheat Sheets
import WNBATrendHistoryCheatSheet from './components/data/basketball/WNBATrendHistoryCheatSheet';

// NBA Basketball Cheat Sheets
import NBATrendHistoryCheatSheet from './components/data/basketball/NBATrendHistoryCheatSheet';
import NBAPositionMetricsReport from './components/data/basketball/NBAPositionMetrics';
import NBAClusterReport from './components/data/basketball/NBAClusterReport';
import NBAPlayerDAvgReport from './components/data/basketball/NBAPlayerDAvg';
import NBAReverseCorrelations from './components/data/basketball/NBAReverseCorrelations';

// Styles
import './styles/global/global.css';

/* 
########################################################################
# Utility Component: NotFound
########################################################################
*/

// A simple component for displaying "Page Not Found"
const NotFound = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <a href="/">Go to Home</a>
  </div>
);

/* 
########################################################################
# Core Logic
########################################################################
*/

function App() {
  return (
    <StripeContext>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={
              <AuthCheck redirectToDashboard>
                <Home />
              </AuthCheck>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/sample-data-page" element={<SampleDataPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />

            <Route path="/dashboard" element={
              <AuthCheck>
                <Dashboard />
              </AuthCheck>
            } />
            <Route path="/generate-slip" element={
              <AuthCheck>
                <GenerateSlipForm />
              </AuthCheck>
            } />
            <Route path="/faq" element={
              <AuthCheck>
                <Faq />
              </AuthCheck>
            } />
            <Route path="/file-issue" element={
              <AuthCheck>
                <FileIssue />
              </AuthCheck>
            } />
            <Route path="/user-settings" element={
              <AuthCheck>
                <UserSettings />
              </AuthCheck>
            } />
            <Route path="/sport/:sport_id" element={
              <AuthCheck>
                <Sport />
              </AuthCheck>
            } />
            <Route path="/game-details/:sportName/:date/:game_id" element={
              <AuthCheck>
                <GameDetailsPage />
              </AuthCheck>
            } />

            {/* Baseball Cheat Sheets */}
            <Route path="/hot-batter-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <HotBatterCheatSheet />
              </AuthCheck>
            } />
            <Route path="/good-history-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <GoodHistoryCheatSheet />
              </AuthCheck>
            } />
            <Route path="/hr-drought-good-history-batter-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <HRDroughtBatterGoodHistoryCheatSheet />
              </AuthCheck>
            } />
            <Route path="/hit-drought-good-history-batter-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <HitDroughtBatterGoodHistoryCheatSheet />
              </AuthCheck>
            } />
            <Route path="/pitcher-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <PitcherCheatSheet />
              </AuthCheck>
            } />
            <Route path="/hr-drought-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <HRDroughtCheatSheet />
              </AuthCheck>
            } />
            <Route path="/hit-drought-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <HitDroughtCheatSheet />
              </AuthCheck>
            } />
            <Route path="/hr-stats" element={<HRStats />} />
            <Route path="/hr-duos" element={<HRDuos />} />
            <Route path="/mlb-trend-history-cheat-sheet/:sport_id/:date" element={
              <AuthCheck>
                <MLBPitcherPropTrendHistoryCheatSheet />
              </AuthCheck>
            } />

            {/* NFL Cheat Sheets */}
            <Route path="/player-cheat-sheet/:sport_id" element={
              <AuthCheck>
                <PlayerCheatSheet />
              </AuthCheck>
            } />
            <Route path="/nfl-trend-history-cheat-sheet/:sport_id" element={
              <AuthCheck>
                <NFLTrendHistoryCheatSheet />
              </AuthCheck>
            } />
            <Route path="/nfl-defense-position-td-cheat-sheet/:sport_id" element={
              <AuthCheck>
                <DefenseVsPositionTDCheatSheet />
              </AuthCheck>
            } />
            <Route path="/nfl-cluster-report/:sport_id" element={
              <AuthCheck>
                <NFLClusterReport />
              </AuthCheck>
            } />
            <Route path="/nfl-reverse-correlations/:sport_id" element={
              <AuthCheck>
                <NFLReverseCorrelations />
              </AuthCheck>
            } />

            {/* WNBA Basketball Cheat Sheets */}
            <Route path="/wnba-trend-history-cheat-sheet/:sport_id" element={
              <AuthCheck>
                <WNBATrendHistoryCheatSheet />
              </AuthCheck>
            } />

            {/* NBA Basketball Cheat Sheets */}
            <Route path="/nba-trend-history-cheat-sheet/:sport_id" element={
              <AuthCheck>
                <NBATrendHistoryCheatSheet />
              </AuthCheck>
            } />
            <Route path="/nba-position-metrics-report/:sport_id" element={
              <AuthCheck>
                <NBAPositionMetricsReport />
              </AuthCheck>
            } />
            <Route path="/nba-cluster-report/:sport_id" element={
              <AuthCheck>
                <NBAClusterReport />
              </AuthCheck>
            } />
            <Route path="/nba-player-opposing-defense-report/:sport_id" element={
              <AuthCheck>
                <NBAPlayerDAvgReport />
              </AuthCheck>
            } />
            <Route path="/nba-reverse-correlations/:sport_id" element={
              <AuthCheck>
                <NBAReverseCorrelations />
              </AuthCheck>
            } />

            {/* Fallback for Malformed or Invalid Routes */}
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </StripeContext>
  );
}

export default App;

/* 
########################################################################
# END
########################################################################
*/
