import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const NFLReverseCorrelations = () => {
  const [reverseCorrelations, setReverseCorrelations] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedGames, setExpandedGames] = useState({});

  const handleError = useCallback((error) => {
    if (error.response) {
      setError(`An unexpected error occurred: ${error.response.statusText}`);
    } else if (error.request) {
      setError('No response received from the server.');
    } else {
      setError(`An unexpected error occurred: ${error.message}`);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchReverseCorrelationsAndGames = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const [gamesResponse, correlationsResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/current-games/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}nfl/reverse-correlations/`,
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ]);

        setGames(gamesResponse.data);
        setReverseCorrelations(
          correlationsResponse.data.nfl_reverse_correlations,
        );
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchReverseCorrelationsAndGames();
  }, [handleError]);

  const toggleGameExpansion = (gameId) => {
    setExpandedGames((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const formatReverseCorrelationBet = (
    metric1,
    metric2,
    odds1,
    odds2,
    predicted1,
    predicted2,
  ) => {
    const bet1 =
      predicted1 > odds1
        ? `Over ${odds1} ${metric1}`
        : `Under ${odds1} ${metric1}`;
    const bet2 =
      predicted2 > odds2
        ? `Over ${odds2} ${metric2}`
        : `Under ${odds2} ${metric2}`;
    return `${bet1} & ${bet2}`;
  };

  if (loading)
    return <LoadingSpinner text="Loading NFL Inverse Correlations..." />;
  if (error)
    return <div className="text-red-600 text-center mt-6">{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      {/* Logo Top Center */}
      <div className="flex justify-center mb-4">
        <img
          src="/assets/images/sportbetdata_full_logo.png"
          alt="SportBetData Logo"
          className="h-36 object-contain"
        />
      </div>

      {/* Page Title */}
      <h1 className="text-3xl font-bold text-center text-[#484242] mb-10">
        NFL Potential Inverse Correlations
      </h1>

      {/* Game Sections */}
      {games.length > 0 ? (
        games.map((game) => (
          <div
            key={game.game_id}
            className="mb-6 border border-neutral-300 rounded-xl bg-white shadow-sm"
          >
            <div
              className="cursor-pointer flex justify-between items-center px-6 py-4 bg-[#484242] text-white rounded-t-xl hover:bg-neutral-800 transition"
              onClick={() => toggleGameExpansion(game.game_id)}
            >
              <span className="font-medium text-base sm:text-lg">
                {game.game_display_name}
              </span>
              {expandedGames[game.game_id] ? (
                <FiChevronUp className="w-5 h-5 text-white" />
              ) : (
                <FiChevronDown className="w-5 h-5 text-white" />
              )}
            </div>

            {expandedGames[game.game_id] && (
              <div className="p-6 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                {reverseCorrelations
                  .filter(
                    (correlation) =>
                      String(correlation.game_id) === String(game.game_id),
                  )
                  .map((correlation, index) => (
                    <div
                      key={index}
                      className="border border-neutral-200 rounded-lg p-4 bg-white hover:shadow-md transition"
                    >
                      <div className="font-semibold text-[#484242] text-base mb-2">
                        {correlation.player_name}
                      </div>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric 1:</span> O/U{' '}
                        {correlation.odds1} {correlation.metric1}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Metric 2:</span> O/U{' '}
                        {correlation.odds2} {correlation.metric2}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Prediction 1:</span>{' '}
                        {correlation.predicted1} {correlation.metric1}
                      </p>
                      <p className="text-sm text-neutral-700 mb-1">
                        <span className="font-medium">Prediction 2:</span>{' '}
                        {correlation.predicted2} {correlation.metric2}
                      </p>
                      <p className="text-sm text-green-700 font-medium">
                        <span className="font-semibold">Bet:</span>{' '}
                        {formatReverseCorrelationBet(
                          correlation.metric1,
                          correlation.metric2,
                          correlation.odds1,
                          correlation.odds2,
                          correlation.predicted1,
                          correlation.predicted2,
                        )}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center text-neutral-500">No games available</div>
      )}
    </div>
  );
};

export default NFLReverseCorrelations;
