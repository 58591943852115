import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../../../api/authApi.js';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser(email, password);
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-white">
      {/* Header Section */}
      <div className="flex flex-col items-center justify-center pt-12 pb-16">
        <div
          className="w-full flex justify-center cursor-pointer mb-8 animate-fadeIn"
          onClick={handleLogoClick}
        >
          <img
            src="/assets/images/sportbetdata_full_logo.png"
            alt="Sport Bet Data Logo"
            className="w-1/2 max-w-xs md:max-w-md hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 animate-slideUp">
          <h2 className="text-2xl font-heading text-neutral mb-4">Login</h2>
          <p className="text-lg text-gray-600 mb-6">
            Welcome! Data insights driven by technology.
          </p>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-primary mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-3 border border-gray-300 rounded focus:ring-2 focus:ring-primary focus:outline-none transition"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-neutral text-white py-3 rounded-lg font-medium hover:bg-black hover:translate-y-[-2px] transition-all duration-300"
            >
              Login
            </button>
          </form>
          {errorMessage && (
            <p className="text-red text-sm mt-4">{errorMessage}</p>
          )}
          <div className="mt-6 text-center">
            <Link
              to="/forgot-password"
              className="text-blue hover:underline text-sm"
            >
              Forgot Password?
            </Link>
            <p className="text-sm mt-4">
              Don’t have an account?{' '}
              <Link to="/register" className="text-blue hover:underline">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
