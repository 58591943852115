import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSportPermissions,
  fetchSportData,
} from '../../../../redux/slices/subscriptionSlice';
import moment from 'moment-timezone';
import LoadingSpinner from '../../../common/LoadingSpinner';
import CheatSheetDropdown from '../../../data/global/CheatSheetDropdown';

// Main Sport component
const Sport = () => {
  const { sport_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expandedDates, setExpandedDates] = useState({});

  const { sportData, sportPermissions, isLoading, error } = useSelector(
    (state) => state.subscription,
  );

  const canViewTomorrow = sportPermissions?.can_view_tomorrow || false;

  // Transform sportData to organize by date
  const sportGamesByDate = sportData.reduce((acc, game) => {
    if (!acc[game.game_date]) {
      acc[game.game_date] = [];
    }
    acc[game.game_date].push(game);
    return acc;
  }, {});

  // Get sport name dynamically
  const sportName = sportData.length > 0 ? sportData[0].sport_name : 'Sport';

  useEffect(() => {
    dispatch(fetchSportPermissions(sport_id))
      .then((res) => {
        if (res.payload) {
          dispatch(
            fetchSportData({ sportId: sport_id, includeTomorrow: false }),
          );
        } else {
          console.error('fetchSportPermissions returned undefined');
        }
      })
      .catch((error) => {
        console.error('Error fetching sport permissions:', error);
      });
  }, [dispatch, sport_id]);

  useEffect(() => {
    if (sportData.length > 0) {
      const expandedDatesState = sportData.reduce((acc, game) => {
        acc[game.game_date] = true;
        return acc;
      }, {});
      setExpandedDates(expandedDatesState);
    }
  }, [sportData]);

  const handleShowPdf = (date, game_id) => {
    navigate(`/game-details/${sportName.toLowerCase()}/${date}/${game_id}`);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <LoadingSpinner text="Loading data for sport..." />;
  }

  const today = moment().tz('America/New_York').format('YYYY-MM-DD');
  const oneWeekAhead = moment()
    .tz('America/New_York')
    .add(7, 'days')
    .format('YYYY-MM-DD');

  // Get future dates (up to 7 days ahead) and today's date
  const upcomingDates = canViewTomorrow
    ? Object.keys(sportGamesByDate || {})
        .filter(
          (date) =>
            moment(date).isSameOrAfter(today) &&
            moment(date).isBefore(oneWeekAhead),
        )
        .sort((a, b) => moment(a).diff(moment(b)))
    : Object.keys(sportGamesByDate || {})
        .filter((date) => moment(date).isSame(today))
        .sort((a, b) => moment(a).diff(moment(b)));

  // Show no predictions message if no games today
  if (!upcomingDates.length) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 text-center">
        <p className="text-xl font-body text-neutral-600 mb-6">
          Player predictions become available after <strong>10 days</strong>{' '}
          into the season for <strong>NBA</strong> & <strong>WNBA</strong>,{' '}
          <strong>20 days</strong> for <strong>MLB</strong>, and start from{' '}
          <strong>Week 4</strong> for <strong>NFL</strong>.
          <br />
          Predictions are typically loaded daily starting at{' '}
          <strong>1 AM EST</strong> and will be available by{' '}
          <strong>1 hour before game time</strong> at the latest. If no games
          are scheduled (e.g., mid-season breaks or All-Star events), feel free
          to reach out, and we will gladly provide a free subscription for
          another day of your choice!
        </p>
        <CheatSheetDropdown sport_id={sport_id} date={today} />
        <button
          onClick={() => navigate('/dashboard')}
          className="mt-6 px-6 py-2 bg-primary text-white rounded-lg shadow hover:bg-primary-600 
                    focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition"
        >
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="flex justify-between items-center px-6 py-4 bg-white shadow-md">
        <button
          className="text-sm bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition-all"
          onClick={() => navigate('/dashboard')}
        >
          Home
        </button>
        <h1 className="text-2xl font-heading text-gray-900">
          {sportName} Game Data
        </h1>
      </div>

      {/* Main Content */}
      <div className="px-6 py-8">
        {upcomingDates.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-heading text-gray-800 mb-4">Games</h2>
            <div className="space-y-6">
              {upcomingDates.map((date, dateIndex) => (
                <div key={date} className="bg-white p-6 shadow rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-body text-gray-800">{date}</h3>
                  </div>

                  {expandedDates[date] && (
                    <div className="space-y-4">
                      {sportGamesByDate[date]?.map((game, gameIndex) => (
                        <div
                          key={gameIndex}
                          className="p-4 rounded-lg shadow-md bg-gray-50"
                        >
                          <h4 className="text-base font-body text-gray-900 mb-2">
                            {game.game_display_name}
                          </h4>
                          <div className="flex justify-between items-center">
                            <button
                              onClick={() => handleShowPdf(date, game.game_id)}
                              className="text-primary hover:text-primary-600 transition"
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      )) || (
                        <p className="text-neutral-600">
                          No games available for this date.
                        </p>
                      )}
                    </div>
                  )}

                  {dateIndex === 0 && (
                    <div className="mt-4 flex gap-4">
                      <CheatSheetDropdown sport_id={sport_id} date={date} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sport;
