import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import '../../styles/data/GameDetails.css';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const WNBAGameDetails = () => {
  const { date, game_id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [teamMetricsData, setTeamMetricsData] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          setError('You do not have an active subscription.');
        } else if (error.response.status === 404) {
          setError('Sport not found.');
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          navigate('/login');
        } else {
          setError(
            `An unexpected error occurred: ${error.response.statusText}`,
          );
        }
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    const checkPermissionsAndFetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        // Check if the user has an active subscription
        const permissionResponse = await axios.get(
          `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}check-sport-permissions/1/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (permissionResponse.status === 200) {
          // Fetch the game data since the user has an active subscription
          const response = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/pdf-data/${date}/${game_id}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setGameData(response.data);

          // Fetch the team metrics data
          const metricsResponse = await axios.get(
            `${process.env.REACT_APP_DJANGO_BASE_URL}${process.env.REACT_APP_DATA_API_TYPE}wnba/team-metrics/${date}/`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setTeamMetricsData(metricsResponse.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkPermissionsAndFetchData();
  }, [date, game_id, navigate, handleError]);

  const toggleExpansion = (teamName, period) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [`${teamName}-${period}`]: !prevExpandedSections[`${teamName}-${period}`],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  const {
    games,
    game_summary,
    player_predictions,
    recent_performances,
    player_odds,
    odd_comparison,
  } = gameData;

  const selectedGame = games.find(
    (game) => game.game_id.toString() === game_id,
  );
  if (!selectedGame) {
    return <div>No game data available for this game ID.</div>;
  }

  const awayTeam = { name: selectedGame.away_team };
  const homeTeam = { name: selectedGame.home_team };

  const awayTeamSummary = game_summary.find(
    (summary) => summary.player_team === awayTeam.name,
  );
  const homeTeamSummary = game_summary.find(
    (summary) => summary.player_team === homeTeam.name,
  );

  const awayTeamPoints = awayTeamSummary
    ? parseFloat(awayTeamSummary.team_points)
    : 0;
  const homeTeamPoints = homeTeamSummary
    ? parseFloat(homeTeamSummary.team_points)
    : 0;
  let totalPoints = awayTeamPoints + homeTeamPoints;
  totalPoints = parseFloat(totalPoints.toFixed(2));

  const mapPlayerOdds = (playerName, playerOdds) => {
    return playerOdds
      .filter((odd) => odd.player_name === playerName)
      .map((odd) => ({
        metric: odd.metric,
        overUnder: odd.over_under,
        bettingOver: parseFloat(odd.betting_100_on_over_would_return),
        bettingUnder: parseFloat(odd.betting_100_on_under_would_return),
      }));
  };

  const createChartData = (
    predictions,
    recentPerformances,
    playerOdds,
    metric,
  ) => {
    const metricKeyMap = {
      Points: 'points',
      Rebounds: 'rebounds',
      Assists: 'assists',
      '3PM': 'threePointMade',
      PRA: 'PRA',
    };

    const metricKey = metricKeyMap[metric];
    const sortedPerformances = recentPerformances.sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    );

    const recentPerformanceValues = sortedPerformances
      .map((p) => {
        if (metric === 'PRA') {
          return (
            parseFloat(p.points) +
            parseFloat(p.rebounds) +
            parseFloat(p.assists)
          );
        }
        return parseFloat(p[metricKey]);
      })
      .filter((v) => !isNaN(v));

    if (recentPerformanceValues.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const predictionValue = parseFloat(
      predictions.find((p) => p.metric === metric).value,
    );
    const vegasLine = playerOdds.find(
      (odd) => odd.metric === metric,
    )?.overUnder;
    const vegasLineValue = vegasLine ? parseFloat(vegasLine) : null;

    return {
      labels: sortedPerformances.map((p) => p.date),
      datasets: [
        {
          label: 'Recent Performances',
          data: recentPerformanceValues,
          pointBackgroundColor: 'yellow',
          pointBorderColor: 'yellow',
          pointRadius: 5,
          showLine: false,
        },
        {
          label: 'Prediction Line',
          data: Array(recentPerformanceValues.length).fill(predictionValue),
          borderColor: 'green',
          borderWidth: 2,
          pointRadius: 0,
        },
        {
          label: 'Vegas Line',
          data:
            vegasLineValue !== null
              ? Array(recentPerformanceValues.length).fill(vegasLineValue)
              : [],
          borderColor: 'red',
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
      },
    },
  };

  const awayPlayers = player_predictions
    .filter(
      (player) =>
        player.player_team === selectedGame.away_team &&
        player.predicted_minutes > 1,
    )
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: '3PM', value: player.predicted_three_point_made },
        { metric: 'PRA', value: player.predicted_points_rebound_assist },
      ],
      recentPerformances: recent_performances
        .filter(
          (performance) =>
            performance.player === player.player_name &&
            performance.player_team === selectedGame.away_team,
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds),
    }));

  const homePlayers = player_predictions
    .filter(
      (player) =>
        player.player_team === selectedGame.home_team &&
        player.predicted_minutes > 1,
    )
    .map((player) => ({
      name: player.player_name,
      predictions: [
        { metric: 'Points', value: player.predicted_points },
        { metric: 'Assists', value: player.predicted_assists },
        { metric: 'Rebounds', value: player.predicted_rebounds },
        { metric: '3PM', value: player.predicted_three_point_made },
        { metric: 'PRA', value: player.predicted_points_rebound_assist },
      ],
      recentPerformances: recent_performances
        .filter(
          (performance) =>
            performance.player === player.player_name &&
            performance.player_team === selectedGame.home_team,
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((performance) => ({
          date: performance.game_date,
          points: performance.points,
          rebounds: performance.rebounds,
          assists: performance.assists,
          threePointMade: performance.three_point_made,
        })),
      playerOdds: mapPlayerOdds(player.player_name, player_odds),
    }));

  // Function to render the metrics based on team and period selection
  const renderTeamMetricsDropdown = (teamName) => {
    const filteredMetrics = teamMetricsData.filter(
      (metric) => metric.team_name === teamName,
    );

    return (
      <div className="metrics-dropdown">
        {filteredMetrics.map((metric, index) => (
          <div key={index}>
            <button
              onClick={() => toggleExpansion(metric.team_name, metric.period)}
              className="period-dropdown"
            >
              {metric.period === 'last_7_days'
                ? 'Last 7'
                : metric.period === 'last_15_days'
                  ? 'Last 15'
                  : metric.period === 'last_30_days'
                    ? 'Last 30'
                    : 'Season'}
            </button>
            {expandedSections[`${metric.team_name}-${metric.period}`] && (
              <div className="nested-dropdown">
                <div className="metric">
                  Average Points: {metric.avg_points}
                </div>
                <div className="metric">
                  Average Threes: {metric.avg_threes_per_game}
                </div>
                <div className="metric">
                  Average Assists: {metric.avg_assists}
                </div>
                <div className="metric">
                  Average Rebounds: {metric.avg_rebounds}
                </div>
                <div className="metric">
                  Average Points Allowed: {metric.avg_points_allowed}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="game-details">
      <div id="game-details-content">
        <div className="game-display-ribbon">
          {selectedGame.game_display_name}
        </div>

        <div className="row centered-content">
          <div className="card">
            <h2>Game Summary</h2>
            <p>
              {awayTeam.name} Projected Total Points: {awayTeamPoints}
            </p>
            <p>
              {homeTeam.name} Projected Total Points: {homeTeamPoints}
            </p>
            <p>
              Point Differential:{' '}
              {Math.abs(awayTeamPoints - homeTeamPoints).toFixed(2)} in favor of{' '}
              {awayTeamPoints > homeTeamPoints ? awayTeam.name : homeTeam.name}
            </p>
            <p>Total Points: {totalPoints}</p>
          </div>
        </div>

        <div className="row centered-content">
          <div>
            <h4>{awayTeam.name} Metrics</h4>
            {renderTeamMetricsDropdown(awayTeam.name)}
          </div>
          <div>
            <h4>{homeTeam.name} Metrics</h4>
            {renderTeamMetricsDropdown(homeTeam.name)}
          </div>
        </div>

        {odd_comparison &&
          odd_comparison.length > 0 &&
          odd_comparison[0]?.vegas_predicted_total !== null &&
          odd_comparison[0]?.vegas_predicted_winner_spread !== null && (
            <div className="additional-section">
              <h2>Odd Comparison</h2>
              <p>
                Vegas Predicted Winner:{' '}
                {odd_comparison[0]?.vegas_predicted_winner}
              </p>
              <p>
                Vegas Predicted Total: {odd_comparison[0].vegas_predicted_total}
              </p>
              <p>
                Vegas Predicted Winner Spread:{' '}
                {odd_comparison[0]?.vegas_predicted_winner_spread}
              </p>
            </div>
          )}

        <div className="team-ribbon">{awayTeam.name}</div>
        <div className="team-details">
          {awayPlayers.map((player) => (
            <div key={player.name} className="player-details">
              <h3>{player.name}</h3>
              <div className="predictions">
                {player.predictions.map((prediction, index) => (
                  <div key={index} className="prediction-card">
                    <h4>{prediction.metric}</h4>
                    <p>{prediction.value}</p>
                    <div className="visualizations">
                      <Line
                        data={createChartData(
                          player.predictions,
                          player.recentPerformances,
                          player.playerOdds,
                          prediction.metric,
                        )}
                        options={chartOptions}
                        width={150}
                        height={150}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="team-ribbon">{homeTeam.name}</div>
        <div className="team-details">
          {homePlayers.map((player) => (
            <div key={player.name} className="player-details">
              <h3>{player.name}</h3>
              <div className="predictions">
                {player.predictions.map((prediction, index) => (
                  <div key={index} className="prediction-card">
                    <h4>{prediction.metric}</h4>
                    <p>{prediction.value}</p>
                    <div className="visualizations">
                      <Line
                        data={createChartData(
                          player.predictions,
                          player.recentPerformances,
                          player.playerOdds,
                          prediction.metric,
                        )}
                        options={chartOptions}
                        width={150}
                        height={150}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WNBAGameDetails;
